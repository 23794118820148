import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a93a54ea"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "form-check d-flex flex-column align-items-start" }
const _hoisted_2 = { class: "d-flex align-items-center" }
const _hoisted_3 = ["type", "id", "disabled", "checked"]
const _hoisted_4 = ["for", "innerHTML"]
const _hoisted_5 = {
  key: 1,
  class: "badge rounded-pill text-bg-warning ms-2"
}
const _hoisted_6 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("input", {
        type: _ctx.type,
        id: _ctx.id,
        disabled: _ctx.disabled,
        checked: _ctx.modelValue,
        onInput: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.updateValue && _ctx.updateValue(...args))),
        class: _normalizeClass(['form-check-input', _ctx.label ? 'me-3' : ''])
      }, null, 42, _hoisted_3),
      (_ctx.label)
        ? (_openBlock(), _createElementBlock("label", {
            key: 0,
            for: _ctx.id,
            class: "form-check-label me-3",
            innerHTML: _ctx.label
          }, null, 8, _hoisted_4))
        : _createCommentVNode("", true),
      (_ctx.isBeta)
        ? (_openBlock(), _createElementBlock("span", _hoisted_5, "BETA"))
        : _createCommentVNode("", true)
    ]),
    (_ctx.hint)
      ? (_openBlock(), _createElementBlock("span", {
          key: 0,
          class: _normalizeClass(["small fst-italic fw-light ps-2", { 'hint-muted': !_ctx.modelValue }]),
          innerHTML: _ctx.hint
        }, null, 10, _hoisted_6))
      : _createCommentVNode("", true)
  ]))
}