<template>
  <!-- Header -->
  <AppHeader
    :title="t('tools.network.ping.title')"
    :description="t('tools.network.ping.description')"
    :icon="['fas', 'project-diagram']"
    iconType="fontawesome"
  />

  <!-- Ping input -->
  <AppContent>
    <AppInputGroup>
      <AppInput
        type="text"
        class="form-control-lg"
        :placeholder="t('tools.network.ping.hostPlaceholder')"
        v-model="sanitizedTarget"
        @input="onInputChange"
      />
      <AppSelectInput
        style="width: 100px !important; max-width: 100px !important"
        id="ping-count"
        :modelValue="pingCount.toString()"
        :options="pingCountOptions"
        @update:modelValue="onPingCountChange"
      />
      <AppSelectInput
        style="width: 120px !important; max-width: 120px !important"
        id="ping-version"
        :modelValue="pingVersion"
        :options="pingVersionOptions"
        @update:modelValue="onPingVersionChange"
      />
      <AppButton
        btnClass="btn btn-secondary"
        :icon="['fas', 'rotate']"
        title="Reload"
        @click="onReload"
        :disabled="!sanitizedTarget || invalidInput"
      />
    </AppInputGroup>
  </AppContent>

  <!-- Ping results -->
  <AppContent
    v-if="
      pingResult.length > 0 && reachable && !invalidInput && !unreachableHost
    "
  >
    <pre>{{ formattedPingResult }}</pre>
  </AppContent>

  <!-- Results loading -->
  <AppLoading v-if="loading"></AppLoading>
  <div
    class="mt-2 row alert d-flex align-items-center"
    :class="alertClass"
    v-if="getAlertMessage"
  >
    <div class="col-1 d-flex justify-content-center align-items-center">
      <font-awesome-icon
        class="pe-1 fa-2xl"
        :icon="['fas', 'triangle-exclamation']"
      />
    </div>
    <div class="col-11">
      <span v-html="getAlertMessage"></span>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, computed, onUnmounted, onMounted } from "vue";
import { debounce } from "@/utils/global/debounce";

import AppHeader from "@/components/card/AppHeader.vue";
import AppContent from "@/components/card/AppContent.vue";
import AppInputGroup from "@/components/input/AppInputGroup.vue";
import AppInput from "@/components/input/AppInput.vue";
import AppLoading from "@/components/card/AppLoading.vue";
import AppSelectInput from "@/components/input/AppSelectInput.vue";
import AppButton from "@/components/button/AppButton.vue";

import { useI18n } from "vue-i18n";

// ######################################
// Regular expression constants
const DOMAIN_REGEX =
  /^(?=.{1,253}$)(?!.*--.*)([a-z0-9][a-z0-9-]{0,61}[a-z0-9]\.){1,127}(?![0-9]*$)([a-z0-9-]{2,63}|[a-z0-9-]{2,30}\.[a-z]{2,})$/i;
const IPV4_REGEX =
  /^((25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9][0-9]|[0-9])\.){3}(25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9][0-9]|[0-9])$/;
const IPV6_REGEX = /^([0-9a-fA-F]{0,4}:){2,7}([0-9a-fA-F]{0,4})?$/;
const SANITIZATION_REGEX = /^[a-zA-Z0-9-.:]*$/;

export default defineComponent({
  name: "PingTool",
  components: {
    AppHeader,
    AppContent,
    AppInputGroup,
    AppInput,
    AppLoading,
    AppSelectInput,
    AppButton,
  },
  setup() {
    // ######################################
    // Component state and refs
    // i18n for internationalization
    const i18n = useI18n();
    const { t } = i18n;

    // Targets and IPs
    const target = ref("");
    const ipAddress = ref("");
    const hostname = ref("");

    // Ping related variables
    const pingVersion = ref("auto");
    const pingResult = ref<(string | number)[]>([]);
    const pingToken = ref(0);
    const pingCount = ref(10);
    const pingCountOptions = ref([
      { label: "5", value: 5, description: "", disabled: false },
      { label: "10", value: 10, description: "", disabled: false },
      { label: "15", value: 15, description: "", disabled: false },
      { label: "20", value: 20, description: "", disabled: false },
      { label: "25", value: 25, description: "", disabled: false },
    ]);
    const pingVersionOptions = ref([
      { label: "Auto", value: "auto", description: "", disabled: false },
      { label: "IPv4", value: "4", description: "", disabled: false },
      { label: "IPv6", value: "6", description: "", disabled: false },
    ]);

    // Errors and statuses
    const unreachableHost = ref(false);
    const dnsError = ref(false);
    const invalidInput = ref(false);
    const emptyInput = ref(false);

    // Loading and reachability status
    const loading = ref(false);
    const reachable = ref(true);

    // Async processes
    let eventSource: EventSource | null = null;
    let debounceTimeout: ReturnType<typeof setTimeout> | null = null;
    let pingProcess: ReturnType<typeof setTimeout> | null = null;

    // ######################################
    // Helper functions
    const isValidDomain = (value: string) => DOMAIN_REGEX.test(value);

    const isValidIP = (value: string) =>
      IPV4_REGEX.test(value) || IPV6_REGEX.test(value);

    const isValidTarget = () =>
      isValidDomain(target.value) || isValidIP(target.value);

    const sanitizeURL = (url: string) => {
      let sanitizedURL = url
        .replace(/(https?:\/\/)?(www\.)?/, "")
        .split("/")[0];
      sanitizedURL = sanitizedURL
        .split("")
        .filter((char) => char.match(SANITIZATION_REGEX))
        .join("");
      return sanitizedURL;
    };

    // ######################################
    // Input and selection change handlers
    const onInputChange = debounce(() => {
      target.value = sanitizeURL(target.value);
      pingResult.value = [];
      clearDebounceTimeout();
      if (pingProcess) {
        cancelPing(); // Cancel the existing ping process if it exists
      }

      // Disable options based on the input value
      if (IPV4_REGEX.test(target.value)) {
        // Select IPv4 option
        pingVersion.value = "4";
        // Disable IPv6 option
        const ipv6Option = pingVersionOptions.value.find(
          (option) => option.value === "6"
        );
        if (ipv6Option) ipv6Option.disabled = true;
        // Enable IPv4 option
        const ipv4Option = pingVersionOptions.value.find(
          (option) => option.value === "4"
        );
        if (ipv4Option) ipv4Option.disabled = false;
      } else if (IPV6_REGEX.test(target.value)) {
        // Select IPv6 option
        pingVersion.value = "6";
        // Disable IPv4 option
        const ipv4Option = pingVersionOptions.value.find(
          (option) => option.value === "4"
        );
        if (ipv4Option) ipv4Option.disabled = true;
        // Enable IPv6 option
        const ipv6Option = pingVersionOptions.value.find(
          (option) => option.value === "6"
        );
        if (ipv6Option) ipv6Option.disabled = false;
      } else {
        // Select auto option
        pingVersion.value = "auto";
        // Enable all options
        pingVersionOptions.value.forEach((option) => (option.disabled = false));
      }

      triggerPing();
    }, 1000);

    const onPingCountChange = (newCount: string) => {
      pingCount.value = parseInt(newCount);
      saveSettings();
      // Cancel previous pings and trigger a new one
      if (isValidTarget()) {
        cancelPing();
        triggerPing();
      }
    };

    const onPingVersionChange = (newVersion: string) => {
      pingVersion.value = newVersion;
      saveSettings();
      // Cancel previous pings and trigger a new one
      if (isValidTarget()) {
        cancelPing();
        triggerPing();
      }
    };

    // ######################################
    // Ping handling functions
    const resetValues = () => {
      reachable.value = true;
      loading.value = false;
      pingResult.value = [];
      invalidInput.value = false;
      unreachableHost.value = false;
    };

    const closeEventSource = () => {
      if (eventSource) {
        eventSource.close();
        eventSource = null;
      }
    };

    const clearDebounceTimeout = () => {
      if (debounceTimeout !== null) {
        clearTimeout(debounceTimeout);
        debounceTimeout = null;
      }
    };

    const triggerPing = () => {
      if (pingProcess) {
        cancelPing(); // Cancel the existing ping process if it exists
      }
      resetValues();
      closeEventSource();

      const token = Math.random();
      pingToken.value = token;

      if (target.value === "") {
        invalidInput.value = true; // Input is invalid if the target is empty
        emptyInput.value = true; // Input is empty
        return;
      }

      emptyInput.value = false; // Input is not empty

      if (!isValidTarget()) {
        reachable.value = false;
        unreachableHost.value = true; // Host is unreachable if the target is not valid
        invalidInput.value = true;
        return;
      }

      loading.value = true;

      const baseUrl = process.env.VUE_APP_API_URL;
      const eventSourceUrl = `${baseUrl}/network/ping/${target.value}/${pingVersion.value}/${pingCount.value}`;

      eventSource = new EventSource(eventSourceUrl);

      eventSource.onmessage = (e) => {
        const data = JSON.parse(e.data);
        loading.value = false;

        if (token !== pingToken.value) {
          return;
        }

        if (data.seq === 1 && data.address) {
          ipAddress.value = data.address;
          hostname.value = data.hostname;
          // Check if the returned IP address does not match with the selected version
          if (
            (pingVersion.value === "6" && IPV4_REGEX.test(data.address)) ||
            (pingVersion.value === "4" && IPV6_REGEX.test(data.address))
          ) {
            cancelPing(); // Cancel the ping process if there is a mismatch
            return;
          }
        }

        if (data.error) {
          if (data.error === "Host not reachable") {
            dnsError.value = true;
          }
          if (!invalidInput.value) {
            unreachableHost.value = true;
          }
        } else {
          reachable.value = true;
          pingResult.value[data.seq - 1] = data.time;
        }

        if (data.seq >= pingCount.value || data.error) {
          if (eventSource !== null) {
            eventSource.close();
            eventSource = null;
          }
        }
      };

      eventSource.onerror = () => {
        loading.value = false;
        unreachableHost.value = true;
        if (!invalidInput.value && dnsError.value) {
          unreachableHost.value = true;
        }
      };

      pingProcess = setTimeout(() => {
        /* no-op */
      }, 0);
    };

    const cancelPing = () => {
      if (eventSource) {
        eventSource.close();
        eventSource = null;
      }
      if (pingProcess) {
        clearTimeout(pingProcess);
        pingProcess = null;
      }
      resetValues();
    };

    // ######################################
    // Cleanup and initialization
    onUnmounted(() => {
      closeEventSource();
      clearDebounceTimeout();
    });

    const onReload = () => {
      onPingVersionChange(pingVersion.value);
    };

    // Load settings from local storage when component is mounted
    onMounted(() => {
      loadSettings();
    });

    // Function to load settings from local storage
    const loadSettings = () => {
      const settings = JSON.parse(
        localStorage.getItem("PingTool_settings") || "{}"
      );

      if (settings.pingCount !== undefined) {
        pingCount.value = settings.pingCount;
      }

      if (settings.pingVersion !== undefined) {
        pingVersion.value = settings.pingVersion;
      }
    };

    // Function to save settings to local storage
    const saveSettings = () => {
      const settings = {
        pingCount: pingCount.value,
        pingVersion: pingVersion.value,
      };

      localStorage.setItem("PingTool_settings", JSON.stringify(settings));
    };

    // ######################################
    // Computed properties
    const sanitizedTarget = computed({
      get: () => target.value,
      set: (newValue) => {
        target.value = sanitizeURL(newValue);
      },
    });

    const formattedPingResult = computed(() => {
      let output = `PING ${target.value}${
        hostname.value ? ` (${hostname.value})` : ""
      }: 56 data bytes\n`;
      pingResult.value.forEach((result, index) => {
        if (typeof result === "number") {
          const addressPart = isValidDomain(target.value)
            ? ` (${ipAddress.value})`
            : "";
          output += `64 bytes from ${target.value}${addressPart}: icmp_seq=${
            index + 1
          } ttl=64 time=${result} ms\n`;
        } else {
          output += `Request timeout for icmp_seq ${index}\n`;
        }
      });
      return output;
    });

    const alertClass = computed(() => {
      if (invalidInput.value) {
        return "alert alert-warning";
      }
      if (unreachableHost.value) {
        return "alert alert-danger";
      }
      return "";
    });

    const getAlertMessage = computed(() => {
      if (invalidInput.value && !emptyInput.value) {
        return t("tools.network.ping.messages.hostNotValid");
      }
      if (unreachableHost.value) {
        if (dnsError.value) {
          return t("tools.network.ping.messages.hostNotReachable");
        }
        return t("tools.network.ping.messages.hostNotReachable");
      }
      return "";
    });

    // ######################################
    // Return component state and methods
    return {
      t,
      sanitizedTarget,
      onInputChange,
      pingVersion,
      onPingVersionChange,
      onReload,
      pingResult,
      formattedPingResult,
      loading,
      reachable,
      getAlertMessage,
      invalidInput,
      alertClass,
      unreachableHost,
      dnsError,
      pingCount,
      pingCountOptions,
      onPingCountChange,
      pingVersionOptions,
    };
  },
});
</script>
