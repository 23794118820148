import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "alert alert-danger"
}
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { class: "col-3 text-end fw-bold table-desc" }
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = { key: 1 }
const _hoisted_7 = { class: "col-3 text-end fw-bold table-desc" }
const _hoisted_8 = { key: 2 }
const _hoisted_9 = { class: "col-3 text-end fw-bold table-desc" }
const _hoisted_10 = { key: 3 }
const _hoisted_11 = { class: "col-3 text-end fw-bold table-desc" }
const _hoisted_12 = { key: 4 }
const _hoisted_13 = { class: "col-3 text-end fw-bold table-desc" }
const _hoisted_14 = { key: 5 }
const _hoisted_15 = { class: "col-3 text-end fw-bold table-desc" }
const _hoisted_16 = { key: 6 }
const _hoisted_17 = { class: "col-3 text-end fw-bold table-desc" }
const _hoisted_18 = { key: 7 }
const _hoisted_19 = { class: "col-3 text-end fw-bold table-desc" }
const _hoisted_20 = { class: "col-3 text-end fw-bold table-desc" }
const _hoisted_21 = { class: "float-end small" }
const _hoisted_22 = { class: "col-3 text-end fw-bold table-desc" }
const _hoisted_23 = { class: "float-end small" }
const _hoisted_24 = { key: 0 }
const _hoisted_25 = { class: "col-3 text-end fw-bold table-desc" }
const _hoisted_26 = { key: 1 }
const _hoisted_27 = { class: "col-3 text-end fw-bold table-desc" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppHeader = _resolveComponent("AppHeader")!
  const _component_AppTextarea = _resolveComponent("AppTextarea")!
  const _component_AppContent = _resolveComponent("AppContent")!
  const _component_AppTable = _resolveComponent("AppTable")!
  const _component_AppAccordionItem = _resolveComponent("AppAccordionItem")!
  const _component_AppAccordion = _resolveComponent("AppAccordion")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_AppHeader, {
      title: $setup.t('tools.ssl.csrDecoder.title'),
      description: $setup.t('tools.ssl.csrDecoder.description'),
      icon: ['fab', 'expeditedssl'],
      iconType: "fontawesome"
    }, null, 8, ["title", "description"]),
    _createVNode(_component_AppContent, null, {
      default: _withCtx(() => [
        _createVNode(_component_AppTextarea, {
          value: $setup.requests,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => {
        $setup.requests = $event;
        $setup.decodeRequests();
      }),
          placeholder: "-----BEGIN CERTIFICATE REQUEST-----\nMIIC6jCCAdICAQAwgaQxCzAJBgNVBAYTAmRlMRAwDgYDVQQIDAdiYXZhcmlhMRQw\nEgYDVQQHDAtFeGFtcGxlY2l0eTEVMBMGA1UECgwMRXhhbXBsZSBHbWJIMRIwEAYD\nVQQLDAlNYXJrZXRpbmcxGzAZBgNVBAMMEnNlcnZlci5leGFtcGxlLmNvbTElMCMG\neh33R10NjC6JgnGxzf5SUCqBSa5vI4+bbZS2BEjpli6sVSgNoQJYNcmBVlnawcGy\nUBd0Y+3hGTEiKs9Yep73BduOQxxFJFiyyDhx4apc9nVgoMgHF2mkW6bfTn5Xcbon\nSMcKPqDppWqgzZRaLPpiN/eZ+js1zD/4lEyQWCbtiyOimyD2lz4ieKkBqkp+J5DO\nBUu2HSpc/TsWHtRjn1uTE2KfG/zt59HV55GwpAjZ\n-----END CERTIFICATE REQUEST-----"
        }, null, 8, ["value"])
      ]),
      _: 1
    }),
    ($setup.decodingError)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString($setup.decodingError), 1))
      : _createCommentVNode("", true),
    ($setup.processedRequests.length)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.processedRequests, (csr, index) => {
            return (_openBlock(), _createElementBlock("div", {
              key: 'csr-' + index
            }, [
              _createVNode(_component_AppAccordion, null, {
                default: _withCtx(() => [
                  _createVNode(_component_AppAccordionItem, {
                    alwaysOpen: "",
                    title: "Request",
                    icon: ['fas', 'key'],
                    collapseId: "requestDetailsPanel-collapse",
                    headerId: "requestDetailsPanel-header",
                    class: "no-padding-accordion"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_AppTable, null, {
                        default: _withCtx(() => [
                          (csr.commonName)
                            ? (_openBlock(), _createElementBlock("tr", _hoisted_3, [
                                _createElementVNode("td", _hoisted_4, _toDisplayString($setup.t("tools.ssl.general.cn")), 1),
                                _createElementVNode("td", {
                                  innerHTML: csr.commonName
                                }, null, 8, _hoisted_5)
                              ]))
                            : _createCommentVNode("", true),
                          (csr.emailAddress)
                            ? (_openBlock(), _createElementBlock("tr", _hoisted_6, [
                                _createElementVNode("td", _hoisted_7, _toDisplayString($setup.t("tools.ssl.csrDecoder.emailAddress")), 1),
                                _createElementVNode("td", null, _toDisplayString(csr.emailAddress), 1)
                              ]))
                            : _createCommentVNode("", true),
                          (csr.sans)
                            ? (_openBlock(), _createElementBlock("tr", _hoisted_8, [
                                _createElementVNode("td", _hoisted_9, _toDisplayString($setup.t("tools.ssl.general.san")), 1),
                                _createElementVNode("td", null, _toDisplayString(csr.sans), 1)
                              ]))
                            : _createCommentVNode("", true),
                          (csr.organization)
                            ? (_openBlock(), _createElementBlock("tr", _hoisted_10, [
                                _createElementVNode("td", _hoisted_11, _toDisplayString($setup.t("tools.ssl.general.organization")), 1),
                                _createElementVNode("td", null, _toDisplayString(csr.organization), 1)
                              ]))
                            : _createCommentVNode("", true),
                          (csr.organizationUnit)
                            ? (_openBlock(), _createElementBlock("tr", _hoisted_12, [
                                _createElementVNode("td", _hoisted_13, _toDisplayString($setup.t("tools.ssl.general.organizationUnit")), 1),
                                _createElementVNode("td", null, _toDisplayString(csr.organizationUnit), 1)
                              ]))
                            : _createCommentVNode("", true),
                          (csr.locality)
                            ? (_openBlock(), _createElementBlock("tr", _hoisted_14, [
                                _createElementVNode("td", _hoisted_15, _toDisplayString($setup.t("tools.ssl.general.locality")), 1),
                                _createElementVNode("td", null, _toDisplayString(csr.locality), 1)
                              ]))
                            : _createCommentVNode("", true),
                          (csr.state)
                            ? (_openBlock(), _createElementBlock("tr", _hoisted_16, [
                                _createElementVNode("td", _hoisted_17, _toDisplayString($setup.t("tools.ssl.general.state")), 1),
                                _createElementVNode("td", null, _toDisplayString(csr.state), 1)
                              ]))
                            : _createCommentVNode("", true),
                          (csr.country)
                            ? (_openBlock(), _createElementBlock("tr", _hoisted_18, [
                                _createElementVNode("td", _hoisted_19, _toDisplayString($setup.t("tools.ssl.general.country")), 1),
                                _createElementVNode("td", null, _toDisplayString(csr.country), 1)
                              ]))
                            : _createCommentVNode("", true),
                          (csr.publicKeySize)
                            ? (_openBlock(), _createElementBlock("tr", {
                                key: 8,
                                class: _normalizeClass(csr.publicKeySecurity.cssStatus)
                              }, [
                                _createElementVNode("td", _hoisted_20, _toDisplayString($setup.t("tools.ssl.general.pubKeySize")), 1),
                                _createElementVNode("td", null, [
                                  _createTextVNode(_toDisplayString(csr.publicKeySize) + " bit (" + _toDisplayString(csr.publicKeyAlgorithm) + ") ", 1),
                                  _createElementVNode("span", _hoisted_21, _toDisplayString(csr.publicKeySecurity.note), 1)
                                ])
                              ], 2))
                            : _createCommentVNode("", true),
                          (csr.signature)
                            ? (_openBlock(), _createElementBlock("tr", {
                                key: 9,
                                class: _normalizeClass(csr.signatureSecurity.cssStatus)
                              }, [
                                _createElementVNode("td", _hoisted_22, _toDisplayString($setup.t("tools.ssl.general.signatureAlgorithm")), 1),
                                _createElementVNode("td", null, [
                                  _createTextVNode(_toDisplayString(csr.signatureAlgorithmSN) + " (" + _toDisplayString(csr.signature) + ") ", 1),
                                  _createElementVNode("span", _hoisted_23, _toDisplayString(csr.signatureSecurity.note), 1)
                                ])
                              ], 2))
                            : _createCommentVNode("", true)
                        ]),
                        _: 2
                      }, 1024)
                    ]),
                    _: 2
                  }, 1024),
                  _createVNode(_component_AppAccordionItem, {
                    title: $setup.t('tools.ssl.general.additionalDetails'),
                    icon: ['fas', 'list-ol'],
                    collapseId: 'additionalDetailsPanel-collapse-' + index,
                    headerId: 'additionalDetailsPanel-header-' + index,
                    class: "no-padding-accordion"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_AppTable, null, {
                        default: _withCtx(() => [
                          (csr.sha1Fingerprint)
                            ? (_openBlock(), _createElementBlock("tr", _hoisted_24, [
                                _createElementVNode("td", _hoisted_25, _toDisplayString($setup.t("tools.ssl.general.sha1Fingerprint")), 1),
                                _createElementVNode("td", null, _toDisplayString(csr.sha1Fingerprint), 1)
                              ]))
                            : _createCommentVNode("", true),
                          (csr.md5Fingerprint)
                            ? (_openBlock(), _createElementBlock("tr", _hoisted_26, [
                                _createElementVNode("td", _hoisted_27, _toDisplayString($setup.t("tools.ssl.general.md5Fingerprint")), 1),
                                _createElementVNode("td", null, _toDisplayString(csr.md5Fingerprint), 1)
                              ]))
                            : _createCommentVNode("", true)
                        ]),
                        _: 2
                      }, 1024)
                    ]),
                    _: 2
                  }, 1032, ["title", "collapseId", "headerId"])
                ]),
                _: 2
              }, 1024)
            ]))
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ], 64))
}