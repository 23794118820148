<template>
  <AppHeader
    :title="t('tools.unix.chmodGenerator.title')"
    :description="t('tools.unix.chmodGenerator.description')"
    :icon="['fab', 'linux']"
    iconType="fontawesome"
  />

  <AppContent>
    <AppTable>
      <tr>
        <td></td>
        <td class="text-center align-middle fw-bold fs-5">
          {{ $t("tools.unix.chmodGenerator.owner") }}
        </td>
        <td class="text-center align-middle fw-bold fs-5">
          {{ $t("tools.unix.chmodGenerator.group") }}
        </td>
        <td class="text-center align-middle fw-bold fs-5">
          {{ $t("tools.unix.chmodGenerator.others") }}
        </td>
      </tr>
      <tr
        v-for="(type, typeIndex) in ['Read', 'Write', 'Execute']"
        :key="typeIndex"
        class="text-end align-middle"
      >
        <td>{{ type }}</td>
        <td
          v-for="(role, roleIndex) in ['Owner', 'Group', 'Others']"
          :key="roleIndex"
        >
          <div class="d-flex justify-content-center align-items-center">
            <AppCheckboxInput
              v-model="(permissions as Record<string, boolean>)[`${role} ${type}`]"
              :id="`${role}${type}`"
              :label="''"
            />
          </div>
        </td>
      </tr>
    </AppTable>

    <div class="row">
      <div class="col">
        <AppInputGroup>
          <span class="input-group-text" id="inputGroup-sizing-lg">
            Numeric notation:
          </span>
          <AppInput
            ref="numericInput"
            id="numericInput"
            type="text"
            aria-label="Sizing example input"
            aria-describedby="inputGroup-sizing-lg"
            :modelValue="numericNotation"
            readonly
            @click="selectNumericValue"
          />
          <AppButton
            btnClass="btn btn-secondary btn-secondary-submit btn-lg"
            @click="copyToClipboard(numericNotation)"
            :icon="['fas', 'copy']"
          />
        </AppInputGroup>
      </div>
      <div class="col">
        <AppInputGroup>
          <span class="input-group-text" id="inputGroup-sizing-lg">
            Symbolic notation:
          </span>
          <AppInput
            ref="symbolicInput"
            id="symbolicInput"
            type="text"
            aria-label="Sizing example input"
            aria-describedby="inputGroup-sizing-lg"
            :modelValue="symbolicNotation"
            readonly
            @click="selectSymbolicValue"
          />
          <AppButton
            btnClass="btn btn-secondary btn-secondary-submit btn-lg"
            @click="copyToClipboard(symbolicNotation)"
            :icon="['fas', 'copy']"
          />
        </AppInputGroup>
      </div>
    </div>
  </AppContent>
</template>

<script lang="ts">
// Importing modules
import { defineComponent, computed, ref } from "vue";

// Importing components
import AppHeader from "@/components/card/AppHeader.vue";
import AppContent from "@/components/card/AppContent.vue";
import AppTable from "@/components/table/AppTable.vue";
import AppInputGroup from "@/components/input/AppInputGroup.vue";
import AppInput from "@/components/input/AppInput.vue";
import AppCheckboxInput from "@/components/input/AppCheckboxInput.vue";
import AppButton from "@/components/button/AppButton.vue";

// Importing locales
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "ChmodGenerator",
  components: {
    AppHeader,
    AppContent,
    AppTable,
    AppInputGroup,
    AppInput,
    AppCheckboxInput,
    AppButton,
  },
  setup() {
    const i18n = useI18n();
    const { t } = i18n;

    const permissions = ref({
      "Owner Read": true,
      "Owner Write": true,
      "Owner Execute": true,
      "Group Read": true,
      "Group Write": true,
      "Group Execute": true,
      "Others Read": true,
      "Others Write": true,
      "Others Execute": true,
    });

    const numericNotation = computed(() => {
      let numeric = 0;
      if (permissions.value["Owner Read"]) numeric += 400;
      if (permissions.value["Owner Write"]) numeric += 200;
      if (permissions.value["Owner Execute"]) numeric += 100;
      if (permissions.value["Group Read"]) numeric += 40;
      if (permissions.value["Group Write"]) numeric += 20;
      if (permissions.value["Group Execute"]) numeric += 10;
      if (permissions.value["Others Read"]) numeric += 4;
      if (permissions.value["Others Write"]) numeric += 2;
      if (permissions.value["Others Execute"]) numeric += 1;
      return numeric.toString().padStart(3, "0");
    });

    const symbolicNotation = computed(() => {
      const owner = `${permissions.value["Owner Read"] ? "r" : "-"}${
        permissions.value["Owner Write"] ? "w" : "-"
      }${permissions.value["Owner Execute"] ? "x" : "-"}`;
      const group = `${permissions.value["Group Read"] ? "r" : "-"}${
        permissions.value["Group Write"] ? "w" : "-"
      }${permissions.value["Group Execute"] ? "x" : "-"}`;
      const others = `${permissions.value["Others Read"] ? "r" : "-"}${
        permissions.value["Others Write"] ? "w" : "-"
      }${permissions.value["Others Execute"] ? "x" : "-"}`;

      return `${owner}${group}${others}`;
    });

    const selectNumericValue = () => {
      const inputElement = document.querySelector(
        "#numericInput"
      ) as HTMLInputElement;
      if (inputElement) {
        inputElement.select();
        navigator.clipboard.writeText(inputElement.value);
      }
    };

    const selectSymbolicValue = () => {
      const inputElement = document.querySelector(
        "#symbolicInput"
      ) as HTMLInputElement;
      if (inputElement) {
        inputElement.select();
        navigator.clipboard.writeText(inputElement.value);
      }
    };

    const copyToClipboard = (value: string) => {
      if (navigator.clipboard) {
        navigator.clipboard.writeText(value);
      } else if (document.execCommand) {
        const textarea = document.createElement("textarea");
        textarea.value = value;
        document.body.appendChild(textarea);
        textarea.select();
        document.execCommand("copy");
        document.body.removeChild(textarea);
      } else {
        console.error("Clipboard functionality not available");
      }
    };

    return {
      t,
      permissions: permissions.value,
      labels: Object.keys(permissions.value),
      numericNotation,
      symbolicNotation,
      copyToClipboard,
      selectNumericValue,
      selectSymbolicValue,
    };
  },
});
</script>
