<template>
  <table class="table">
    <tbody>
      <slot />
    </tbody>
  </table>
</template>

<script lang="ts">
// Importing modules
import { defineComponent } from "vue";

export default defineComponent({
  name: "AppTable",
});
</script>
