import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = ["id", "value"]
const _hoisted_2 = ["value", "disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("select", {
    id: _ctx.id,
    value: _ctx.modelValue,
    onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.updateValue && _ctx.updateValue(...args))),
    class: "form-select"
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option) => {
      return (_openBlock(), _createElementBlock("option", {
        key: option.value,
        value: option.value,
        disabled: option.disabled
      }, _toDisplayString(option.label) + " " + _toDisplayString(option.description), 9, _hoisted_2))
    }), 128))
  ], 40, _hoisted_1))
}