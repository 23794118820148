<template>
  <div class="card mt-5 pt-3 pb-1 pe-2">
    <div class="row">
      <div
        class="col-sm-2 d-flex align-items-center justify-content-center pt-3 pb-3"
      >
        <font-awesome-icon
          v-if="iconType === 'fontawesome'"
          :icon="icon"
          class="fa-5x"
        />
        <img
          v-else-if="iconType === 'image'"
          :src="iconSrc"
          alt="Icon"
          class="fa-5x"
        />
      </div>
      <div class="col-sm-10 pt-1">
        <h2>{{ title }}</h2>
        <p>{{ description }}</p>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
// Importing FontAwesome
import { defineComponent, PropType } from "vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";

library.add(fas, fab, far);

export default defineComponent({
  name: "HeaderCard",
  components: {
    FontAwesomeIcon,
  },
  props: {
    title: String,
    description: String,
    icon: {
      type: [Array, String] as PropType<Array<string> | string>,
      required: true,
    },
    iconType: {
      type: String,
      default: "fontawesome",
      validator: function (value: string) {
        return ["fontawesome", "image"].includes(value);
      },
    },
  },
  computed: {
    iconSrc(): string {
      if (Array.isArray(this.icon)) {
        return this.icon[0];
      }
      return this.icon;
    },
  },
});
</script>
