<template>
  <!-- Header -->
  <AppHeader
    :title="t('tools.ssl.csrDecoder.title')"
    :description="t('tools.ssl.csrDecoder.description')"
    :icon="['fab', 'expeditedssl']"
    iconType="fontawesome"
  />

  <!-- CSR input -->
  <AppContent>
    <AppTextarea
      :value="requests"
      @update:modelValue="
        requests = $event;
        decodeRequests();
      "
      placeholder="-----BEGIN CERTIFICATE REQUEST-----
MIIC6jCCAdICAQAwgaQxCzAJBgNVBAYTAmRlMRAwDgYDVQQIDAdiYXZhcmlhMRQw
EgYDVQQHDAtFeGFtcGxlY2l0eTEVMBMGA1UECgwMRXhhbXBsZSBHbWJIMRIwEAYD
VQQLDAlNYXJrZXRpbmcxGzAZBgNVBAMMEnNlcnZlci5leGFtcGxlLmNvbTElMCMG
eh33R10NjC6JgnGxzf5SUCqBSa5vI4+bbZS2BEjpli6sVSgNoQJYNcmBVlnawcGy
UBd0Y+3hGTEiKs9Yep73BduOQxxFJFiyyDhx4apc9nVgoMgHF2mkW6bfTn5Xcbon
SMcKPqDppWqgzZRaLPpiN/eZ+js1zD/4lEyQWCbtiyOimyD2lz4ieKkBqkp+J5DO
BUu2HSpc/TsWHtRjn1uTE2KfG/zt59HV55GwpAjZ
-----END CERTIFICATE REQUEST-----"
    />
  </AppContent>

  <!-- Error messages -->
  <div v-if="decodingError" class="alert alert-danger">
    {{ decodingError }}
  </div>

  <!-- CSR decoder output -->
  <div v-if="processedRequests.length">
    <div v-for="(csr, index) in processedRequests" :key="'csr-' + index">
      <AppAccordion>
        <AppAccordionItem
          alwaysOpen
          title="Request"
          :icon="['fas', 'key']"
          collapseId="requestDetailsPanel-collapse"
          headerId="requestDetailsPanel-header"
          class="no-padding-accordion"
        >
          <AppTable>
            <tr v-if="csr.commonName">
              <td class="col-3 text-end fw-bold table-desc">
                {{ t("tools.ssl.general.cn") }}
              </td>
              <td v-html="csr.commonName"></td>
            </tr>
            <tr v-if="csr.emailAddress">
              <td class="col-3 text-end fw-bold table-desc">
                {{ t("tools.ssl.csrDecoder.emailAddress") }}
              </td>
              <td>{{ csr.emailAddress }}</td>
            </tr>
            <tr v-if="csr.sans">
              <td class="col-3 text-end fw-bold table-desc">
                {{ t("tools.ssl.general.san") }}
              </td>
              <td>{{ csr.sans }}</td>
            </tr>
            <tr v-if="csr.organization">
              <td class="col-3 text-end fw-bold table-desc">
                {{ t("tools.ssl.general.organization") }}
              </td>
              <td>{{ csr.organization }}</td>
            </tr>
            <tr v-if="csr.organizationUnit">
              <td class="col-3 text-end fw-bold table-desc">
                {{ t("tools.ssl.general.organizationUnit") }}
              </td>
              <td>{{ csr.organizationUnit }}</td>
            </tr>
            <tr v-if="csr.locality">
              <td class="col-3 text-end fw-bold table-desc">
                {{ t("tools.ssl.general.locality") }}
              </td>
              <td>{{ csr.locality }}</td>
            </tr>
            <tr v-if="csr.state">
              <td class="col-3 text-end fw-bold table-desc">
                {{ t("tools.ssl.general.state") }}
              </td>
              <td>{{ csr.state }}</td>
            </tr>
            <tr v-if="csr.country">
              <td class="col-3 text-end fw-bold table-desc">
                {{ t("tools.ssl.general.country") }}
              </td>
              <td>{{ csr.country }}</td>
            </tr>
            <tr
              v-if="csr.publicKeySize"
              :class="csr.publicKeySecurity.cssStatus"
            >
              <td class="col-3 text-end fw-bold table-desc">
                {{ t("tools.ssl.general.pubKeySize") }}
              </td>
              <td>
                {{ csr.publicKeySize }} bit ({{ csr.publicKeyAlgorithm }})
                <span class="float-end small">
                  {{ csr.publicKeySecurity.note }}
                </span>
              </td>
            </tr>
            <tr v-if="csr.signature" :class="csr.signatureSecurity.cssStatus">
              <td class="col-3 text-end fw-bold table-desc">
                {{ t("tools.ssl.general.signatureAlgorithm") }}
              </td>
              <td>
                {{ csr.signatureAlgorithmSN }} ({{ csr.signature }})
                <span class="float-end small">
                  {{ csr.signatureSecurity.note }}
                </span>
              </td>
            </tr>
          </AppTable>
        </AppAccordionItem>

        <!-- Additional request details -->
        <AppAccordionItem
          :title="t('tools.ssl.general.additionalDetails')"
          :icon="['fas', 'list-ol']"
          :collapseId="'additionalDetailsPanel-collapse-' + index"
          :headerId="'additionalDetailsPanel-header-' + index"
          class="no-padding-accordion"
        >
          <AppTable>
            <tr v-if="csr.sha1Fingerprint">
              <td class="col-3 text-end fw-bold table-desc">
                {{ t("tools.ssl.general.sha1Fingerprint") }}
              </td>
              <td>{{ csr.sha1Fingerprint }}</td>
            </tr>
            <tr v-if="csr.md5Fingerprint">
              <td class="col-3 text-end fw-bold table-desc">
                {{ t("tools.ssl.general.md5Fingerprint") }}
              </td>
              <td>{{ csr.md5Fingerprint }}</td>
            </tr>
          </AppTable>
        </AppAccordionItem>
      </AppAccordion>
    </div>
  </div>
</template>

<script lang="ts">
// Importing modules
import { ref, onUnmounted, computed } from "vue";
import { debounce } from "@/utils/global/debounce";
import { isValidCsr } from "@/utils/ssl/pemFormatValidation";

// Importing components
import AppHeader from "@/components/card/AppHeader.vue";
import AppContent from "@/components/card/AppContent.vue";
import AppAccordion from "@/components/accordion/AppAccordion.vue";
import AppAccordionItem from "@/components/accordion/AppAccordionItem.vue";
import AppTextarea from "@/components/textarea/AppTextarea.vue";
import AppTable from "@/components/table/AppTable.vue";

// Importing helper
import {
  vulnerableAlgorithms,
  insecureAlgorithms,
  secureAlgorithms,
} from "../../utils/ssl/decoder/secureSigAlgorithms";

// Importing locales
import { useI18n } from "vue-i18n";

// Interface declaration
interface PublicKeySecurity {
  cssStatus: string;
  note: string;
}

interface SignatureSecurity {
  cssStatus: string;
  note: string;
}

interface Request {
  commonName: string | null;
  emailAddress: string | null;
  organization: string | null;
  organizationUnit: string | null;
  sans: string | null;
  locality: string | null;
  state: string | null;
  country: string | null;
  publicKeyAlgorithm: string | null;
  publicKeySize: number | null;
  signature: number | null;
  signatureAlgorithmSN: string | null;
  sha1Fingerprint: string | null;
  md5Fingerprint: string | null;
  publicKeySecurity: PublicKeySecurity | null;
  signatureSecurity: SignatureSecurity | null;
}

export default {
  components: {
    AppHeader,
    AppContent,
    AppAccordion,
    AppAccordionItem,
    AppTextarea,
    AppTable,
  },
  setup() {
    const requests = ref("");
    const rawRequests = ref<Request[]>([]);
    const errorValue = ref("");
    const decodingError = ref("");
    const abortController = new AbortController();
    const { t } = useI18n();

    const processedRequests = computed(() => {
      return rawRequests.value.map((request) => {
        let crtTyp = "";
        const commonName = request.commonName?.toUpperCase() || "";
        const subjectAltName = request.sans?.toUpperCase() || "";
        const organization = request.organization?.toUpperCase() || "";

        if (
          commonName.includes("*.") ||
          (subjectAltName && subjectAltName.includes("*."))
        ) {
          crtTyp = t("tools.ssl.general.certTypes.wildcard");
        } else if (!commonName.includes(".")) {
          if (
            (commonName.includes(" CA") ||
              commonName.includes("CA ") ||
              commonName.includes("CA")) &&
            !commonName.includes("LOCALHOST") &&
            !commonName.includes("ROOT") &&
            !organization.includes("ROOT")
          ) {
            crtTyp = t("tools.ssl.general.certTypes.intermediate");
          } else if (
            commonName.includes("ROOT") ||
            organization.includes("ROOT")
          ) {
            crtTyp = t("tools.ssl.general.certTypes.root");
          } else {
            crtTyp = t("tools.ssl.general.certTypes.codeSigning");
          }
        }

        let publicKeySecurity = { cssStatus: "", note: "" };

        // Check if the public key size is available and it's RSA encryption
        if (request.publicKeyAlgorithm === "rsaEncryption") {
          if ((request.publicKeySize ?? 0) >= 4096) {
            publicKeySecurity.cssStatus = "bg-success";
          } else if (
            (request.publicKeySize ?? 0) < 3000 &&
            (request.publicKeySize ?? 0) >= 2048
          ) {
            publicKeySecurity.cssStatus = "bg-warning";
            publicKeySecurity.note = t("tools.ssl.general.messages.pubKeyWeak");
          } else if ((request.publicKeySize ?? 0) < 2048) {
            publicKeySecurity.cssStatus = "bg-danger";
            publicKeySecurity.note = t(
              "tools.ssl.general.messages.pubKeyInsecure"
            );
          }
        }

        // Check if the public key size is available and it's EC encryption
        if (request.publicKeyAlgorithm === "id-ecPublicKey") {
          if ((request.publicKeySize ?? 0) >= 256) {
            publicKeySecurity.cssStatus = "bg-success";
          } else {
            publicKeySecurity.cssStatus = "bg-danger";
            publicKeySecurity.note = t(
              "tools.ssl.general.messages.pubKeyInsecure"
            );
          }
        }

        let signatureSecurity = { cssStatus: "", note: "" };

        // Check the signature algorithm
        if (request.signatureAlgorithmSN) {
          if (vulnerableAlgorithms.includes(request.signatureAlgorithmSN)) {
            signatureSecurity.cssStatus = "bg-danger";
            signatureSecurity.note = t(
              "tools.ssl.general.messages.signatureInsecure"
            );
          } else if (
            insecureAlgorithms.includes(request.signatureAlgorithmSN)
          ) {
            signatureSecurity.cssStatus = "bg-warning";
            signatureSecurity.note = t(
              "tools.ssl.general.messages.signatureWeak"
            );
          } else if (secureAlgorithms.includes(request.signatureAlgorithmSN)) {
            signatureSecurity.cssStatus = "bg-success";
          }
        }

        return {
          ...request,
          commonName: `${request.commonName || ""} ${crtTyp}`,
          publicKeySecurity,
          signatureSecurity,
        };
      });
    });

    const decodeRequests = debounce(async function () {
      errorValue.value = "";
      decodingError.value = "";

      let reqs = requests.value
        .replace(/NEW CERTIFICATE REQUEST/g, "CERTIFICATE REQUEST")
        .split("-----END CERTIFICATE REQUEST-----")
        .map((req) => req.trim() + "\n-----END CERTIFICATE REQUEST-----")
        .filter((req) => req.includes("-----BEGIN CERTIFICATE REQUEST-----"));

      reqs = [...new Set(reqs)];

      const validReqs = reqs.filter((req) => isValidCsr(req));

      if (validReqs.length === 0) {
        errorValue.value = "No valid PEM-formatted requests found.";
        return;
      }

      try {
        const baseUrl = process.env.VUE_APP_API_URL;
        const apiUrl = `${baseUrl}/ssl/csrDecoder`;

        const res = await fetch(apiUrl, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ requests: validReqs }),
          signal: abortController.signal,
        });

        if (!res.ok) {
          errorValue.value = `Server responded with status code ${res.status}`;
          return;
        }

        const data = await res.json();

        let decodingErrors: string[] = [];

        // Clear previous successful decodes
        rawRequests.value = [];

        for (const req of data.requests) {
          if (req.error) {
            decodingErrors.push(req.error);
          } else {
            // Convert the fingerprints to uppercase
            req.sha1Fingerprint = req.sha1Fingerprint?.toLowerCase() ?? null;
            req.md5Fingerprint = req.md5Fingerprint?.toLowerCase() ?? null;

            rawRequests.value.push(req);
          }
        }

        if (decodingErrors.length > 0) {
          decodingError.value =
            "We were unable do decode one or more requests due to some conflicts. Please review your requests!";
        }
      } catch (err) {
        if (err instanceof Error) {
          errorValue.value = err.message;
        } else {
          errorValue.value = "An error occurred";
        }
      }
    }, 1000);

    //watchEffect(() => {
    //  debouncedDecodeRequests();
    //});

    onUnmounted(() => {
      abortController.abort();
    });

    return {
      t,
      requests,
      errorValue,
      decodingError,
      processedRequests,
      decodeRequests,
    };
  },
};
</script>
