export function formatDate(dateString: string, locale: string): string {
  // Normalize the date string to always have one space between the month and the day
  const normalizedDateString = dateString.replace(/\s{2,}/g, " ");

  // Transform "Jan 1 08:13:37 2024 GMT" to "Jan 01 2024 08:13:37 GMT"
  const parts = normalizedDateString.split(" ");
  const day = parts[1].trim().padStart(2, "0"); // Ensure day is always two digits
  const reordered = `${parts[0]} ${day} ${parts[4]} ${parts[2]} GMT`;
  const date = new Date(reordered);

  const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    timeZoneName: "short",
  };

  let formattedDate = "";

  try {
    formattedDate = new Intl.DateTimeFormat(locale, options).format(date);
  } catch (e) {
    console.error("Error formatting date:", e);
    return "Invalid date";
  }

  // extract timezone
  const timezone = formattedDate.split(" ").slice(-1)[0];

  if (locale === "de") {
    formattedDate =
      formattedDate.replace(timezone, "").trim() + " Uhr (" + timezone + ")";
  } else {
    formattedDate =
      formattedDate.replace(timezone, "").trim() + " (" + timezone + ")";
  }

  return formattedDate;
}
