import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "app" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppNavbar = _resolveComponent("AppNavbar")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_AppContainer = _resolveComponent("AppContainer")!
  const _component_AppFooter = _resolveComponent("AppFooter")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_AppNavbar, { "toggle-theme": _ctx.toggleTheme }, null, 8, ["toggle-theme"]),
    _createVNode(_component_AppContainer, null, {
      default: _withCtx(() => [
        _createVNode(_component_router_view)
      ]),
      _: 1
    }),
    _createVNode(_component_AppFooter, { "toggle-theme": _ctx.toggleTheme }, null, 8, ["toggle-theme"])
  ]))
}