import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "card mt-5 pt-3 pb-1 pe-2" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "col-sm-2 d-flex align-items-center justify-content-center pt-3 pb-3" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "col-sm-10 pt-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (_ctx.iconType === 'fontawesome')
          ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
              key: 0,
              icon: _ctx.icon,
              class: "fa-5x"
            }, null, 8, ["icon"]))
          : (_ctx.iconType === 'image')
            ? (_openBlock(), _createElementBlock("img", {
                key: 1,
                src: _ctx.iconSrc,
                alt: "Icon",
                class: "fa-5x"
              }, null, 8, _hoisted_4))
            : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("h2", null, _toDisplayString(_ctx.title), 1),
        _createElementVNode("p", null, _toDisplayString(_ctx.description), 1)
      ])
    ])
  ]))
}