<template>
  <div class="form-check d-flex flex-column align-items-start">
    <div class="d-flex align-items-center">
      <input
        :type="type"
        :id="id"
        :disabled="disabled"
        :checked="modelValue"
        @input="updateValue"
        :class="['form-check-input', label ? 'me-3' : '']"
      />
      <label
        :for="id"
        class="form-check-label me-3"
        v-if="label"
        v-html="label"
      ></label>
      <span class="badge rounded-pill text-bg-warning ms-2" v-if="isBeta"
        >BETA</span
      >
    </div>
    <span
      class="small fst-italic fw-light ps-2"
      :class="{ 'hint-muted': !modelValue }"
      v-if="hint"
      v-html="hint"
    ></span>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "AppCheckboxInput",
  props: {
    label: {
      type: String,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      default: "checkbox",
    },
    modelValue: {
      type: Boolean,
      required: true,
    },
    isBeta: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    hint: {
      type: String,
    },
  },
  methods: {
    updateValue(e: Event) {
      const target = e.target as HTMLInputElement;
      this.$emit("update:modelValue", target.checked);
    },
  },
});
</script>

<style scoped>
.hint-muted {
  opacity: 0.6;
}
</style>
