<template>
  <footer class="text-center mt-5">
    &copy; 2018-{{ currentYear }} Copyright by TechGeeks.io. All rights
    reserved.<br />
    <span class="footer-desc">
      This is a non-commercial website - Made with
      <font-awesome-icon :icon="['fas', 'heart']" class="ms-1 me-1" />
      from a Geek for Geeks
    </span>
  </footer>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "AppFooter",
  data() {
    return {
      currentYear: new Date().getFullYear(),
    };
  },
});
</script>
