<template>
  <AppHeader
    :title="t('tools.ssl.certLookup.title')"
    :description="t('tools.ssl.certLookup.description')"
    :icon="['fas', 'globe']"
    iconType="fontawesome"
  />

  <AppContent>
    <AppInputGroup>
      <AppInput
        type="text"
        class="form-control-lg"
        :placeholder="t('tools.ssl.certLookup.hostPlaceholder')"
        v-model="hostname"
      />
      <AppSelectInput
        style="width: 120px !important; max-width: 120px !important"
        id="ssl-port"
        v-model="sslPort"
        :options="sslPortOptions"
      />
    </AppInputGroup>
  </AppContent>

  <div v-if="ipAddress && !loading && !getAlertMessage">
    <AppContent>
      <div class="row" v-if="isHostnameInCert !== null">
        <div class="col-md-1 d-flex justify-content-center">
          <font-awesome-icon
            :icon="
              isHostnameInCert
                ? ['fas', 'check-circle']
                : ['fas', 'minus-circle']
            "
            size="2x"
            :class="isHostnameInCert ? 'text-success' : 'text-danger'"
          />
        </div>
        <div class="col-md-11">
          <span
            v-if="isHostnameInCert === true"
            v-html="t('tools.ssl.certLookup.messages.isHostnameInCert')"
          ></span>
          <span
            v-else-if="isHostnameInCert === false"
            v-html="t('tools.ssl.certLookup.messages.isHostnameNotInCert')"
          ></span>
        </div>
      </div>
      <div class="row pt-3" v-if="verification">
        <div class="col-md-1 d-flex justify-content-center">
          <font-awesome-icon
            :icon="
              isLastChainMemberRoot
                ? ['fas', 'check-circle']
                : ['fas', 'minus-circle']
            "
            size="2x"
            :class="isLastChainMemberRoot ? 'text-success' : 'text-warning'"
          />
        </div>
        <div class="col-md-11">
          <span
            v-if="isLastChainMemberRoot"
            v-html="t('tools.ssl.certLookup.messages.verificationOk')"
          ></span>
          <span
            v-else
            v-html="t('tools.ssl.certLookup.messages.verificationWarning')"
          ></span>
        </div>
      </div>
      <hr />
      <div class="row" v-if="ipAddress">
        <div class="col-md-1 d-flex justify-content-center">
          <font-awesome-icon
            :icon="['fas', 'info-circle']"
            size="2x"
            class="text-info"
          />
        </div>
        <div class="col-md-11">
          <span v-html="t('tools.ssl.certLookup.messages.reachable')"> </span>
          <b>{{ ipAddress }}</b> -
          <span v-html="t('tools.ssl.certLookup.messages.serverType')"> </span>
          <b>{{ serverType }}</b>
          <img
            v-if="serverTypeLogoPath"
            :src="serverTypeLogoPath"
            alt="Server Type logo"
            height="20"
            class="serverTypeLogo"
          />
        </div>
      </div>
    </AppContent>

    <AppAccordion>
      <AppAccordionItem
        alwaysOpen
        title="Certificate Lookup"
        :icon="['fas', 'info-circle']"
        collapseId="certLookupDetailsPanel-collapse"
        headerId="icertLookupDetailsPanel-header"
        class="no-padding-accordion"
      >
        <AppTable>
          <tr v-if="cn">
            <td class="col-3 text-end fw-bold table-desc">
              {{ t("tools.ssl.general.cn") }}
            </td>
            <td>
              <span v-if="cn === hostname" class="badge text-bg-success">{{
                cn
              }}</span>
              <span v-else>{{ cn }}</span>
            </td>
          </tr>
          <tr v-if="sanEntries && sanEntries.length">
            <td class="col-3 text-end fw-bold table-desc">
              {{ t("tools.ssl.general.san") }}
            </td>
            <td>
              <span v-for="(entry, index) in sanEntries" :key="index">
                <span
                  v-if="
                    entry.replace(/DNS:/g, '').replace('IP Address:', '') ===
                    hostname
                  "
                  class="badge text-bg-success"
                  >{{
                    entry.replace(/DNS:/g, "").replace("IP Address:", "")
                  }}</span
                >
                <span v-else>{{
                  entry.replace(/DNS:/g, "").replace("IP Address:", "")
                }}</span>
                <span v-if="index < sanEntries.length - 1">, </span>
              </span>
            </td>
          </tr>
          <tr v-if="certValidFromDate !== null">
            <td class="col-3 text-end fw-bold table-desc">
              {{ t("tools.ssl.general.validFrom") }}
            </td>
            <td>{{ formatDate(certValidFromDate, locale) }}</td>
          </tr>
          <tr :class="remainingDays?.cssStatus">
            <td class="col-3 text-end fw-bold table-desc">
              {{ t("tools.ssl.general.validTo") }}
            </td>
            <td>
              {{ certValidToDate ? formatDate(certValidToDate, locale) : "" }}
              <span v-if="remainingDays && remainingDays.remainingDays > 0">
                ({{ remainingDays.remainingDays }}
                {{ t("tools.ssl.general.daysRemaining") }})
              </span>
              <span v-else-if="remainingDays">
                ({{ -remainingDays.remainingDays }}
                {{ t("tools.ssl.general.daysOverIt") }})
              </span>
              <span class="float-end small">
                {{ remainingDays?.note }}
              </span>
            </td>
          </tr>
          <tr v-for="(pem, index) in chain" :key="index">
            <td class="col-3 text-end fw-bold table-desc">
              {{ t("tools.ssl.certLookup.certificateChainMember") }}
              #{{ index + 1 }}<br />
              <div class="pt-1">
                <span
                  v-if="pem.certType === t('tools.ssl.general.certTypes.main')"
                  v-html="t('tools.ssl.general.certTypes.main')"
                ></span>
                <span
                  v-if="
                    pem.certType === t('tools.ssl.general.certTypes.wildcard')
                  "
                  v-html="t('tools.ssl.general.certTypes.wildcard')"
                  class="pt-4"
                ></span>
                <span
                  v-if="
                    pem.certType ===
                    t('tools.ssl.general.certTypes.intermediate')
                  "
                  v-html="t('tools.ssl.general.certTypes.intermediate')"
                ></span>
                <span
                  v-if="pem.certType === t('tools.ssl.general.certTypes.root')"
                  v-html="t('tools.ssl.general.certTypes.root')"
                ></span>
              </div>
              <div class="pt-1">
                <a
                  href="#"
                  data-bs-toggle="collapse"
                  :data-bs-target="'#pemCollapse' + index"
                  role="button"
                  aria-expanded="false"
                  :aria-controls="'pemCollapse' + index"
                >
                  <small>[ Show PEM ]</small>
                </a>
              </div>
            </td>
            <td>
              <span v-if="pem.subjectCN && !pem.subjectCN.includes('.')">
                <strong>Subject:</strong>
                {{ pem.subjectCN }}
                <span
                  v-if="
                    pem.subjectOrganization &&
                    pem.subjectOU &&
                    pem.subjectCountry
                  "
                >
                  ({{ pem.subjectOrganization }} - {{ pem.subjectOU }},
                  {{ pem.subjectCountry }})
                </span>
                <span v-else-if="pem.subjectOrganization && pem.subjectCountry">
                  ({{ pem.subjectOrganization }}, {{ pem.subjectCountry }})
                </span>
                <span v-else-if="pem.subjectOU && pem.subjectCountry">
                  ({{ pem.subjectOU }}, {{ pem.subjectCountry }})
                </span>
                <br
              /></span>
              <span v-if="pem.issuerCN">
                <strong>Issuer:</strong>
                {{ pem.issuerCN }}
                <span
                  v-if="
                    pem.issuerOrganization && pem.issuerOU && pem.issuerCountry
                  "
                >
                  ({{ pem.issuerOrganization }} - {{ pem.issuerOU }},
                  {{ pem.issuerCountry }})
                </span>
                <span v-else-if="pem.issuerOrganization && pem.issuerCountry">
                  ({{ pem.issuerOrganization }}, {{ pem.issuerCountry }})
                </span>
                <span v-else-if="pem.issuerOU && pem.issuerCountry">
                  ({{ pem.issuerOU }}, {{ pem.issuerCountry }})
                </span>
              </span>
              <hr />
              <span v-if="pem.validFrom">
                <strong>Valid From:</strong>
                {{ formatDate(pem.validFrom, locale) }} </span
              ><br />
              <span v-if="pem.validTo">
                <strong>Valid To:</strong> {{ formatDate(pem.validTo, locale) }}
              </span>
              <div class="collapse" :id="'pemCollapse' + index">
                <AppTextarea :modelValue="pem.pem" :readonly="true" />
              </div>
            </td>
          </tr>
        </AppTable>
      </AppAccordionItem>
    </AppAccordion>
  </div>

  <AppLoading
    v-if="loading && !getAlertMessage && isValidHostnameOrIp"
  ></AppLoading>

  <div class="container">
    <div
      class="mt-3 row alert d-flex align-items-center"
      :class="alertClass"
      v-if="getAlertMessage && hostname !== ''"
    >
      <div class="col-1 d-flex justify-content-center align-items-center">
        <font-awesome-icon
          class="pe-1 fa-2xl"
          :icon="['fas', 'triangle-exclamation']"
        />
      </div>
      <div class="col-11">
        <span v-html="getAlertMessage"></span>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { ref, Ref, computed, watch, inject, provide } from "vue";
import { useI18n } from "vue-i18n";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import validator from "validator";

import { formatDate } from "@/utils/global/formatDate";
import { debounce } from "@/utils/global/debounce";
import { calculateRemainingDays } from "@/utils/ssl/calculateRemainingDays";

import AppHeader from "@/components/card/AppHeader.vue";
import AppContent from "@/components/card/AppContent.vue";
import AppInput from "@/components/input/AppInput.vue";
import AppInputGroup from "@/components/input/AppInputGroup.vue";
import AppSelectInput from "@/components/input/AppSelectInput.vue";
import AppAccordion from "@/components/accordion/AppAccordion.vue";
import AppAccordionItem from "@/components/accordion/AppAccordionItem.vue";
import AppTextarea from "@/components/textarea/AppTextarea.vue";
import AppTable from "@/components/table/AppTable.vue";
import AppLoading from "@/components/card/AppLoading.vue";

export default {
  components: {
    AppHeader,
    AppContent,
    AppInput,
    AppInputGroup,
    AppSelectInput,
    AppTable,
    AppAccordion,
    AppAccordionItem,
    AppTextarea,
    AppLoading,
  },
  setup() {
    // Define interfaces
    interface RemainingDays {
      remainingDays: number;
      cssStatus: string;
      note: string;
    }
    interface Pem {
      pem: string;
      subjectCountry: string;
      subjectOrganization: string;
      subjectCN: string;
      subjectOU: string;
      issuerCountry: string;
      issuerOrganization: string;
      issuerCN: string;
      issuerOU: string;
      validFrom: string;
      validTo: string;
      certType: string;
      sanEntries?: string[];
    }

    // Define refs
    const hostname = ref("");
    const ipAddress = ref("");
    const serverType = ref("");
    const isHostnameInCert = ref(null);
    const certValidFromDate = ref<string | null>(null);
    const certValidToDate = ref<string | null>(null);
    const remainingDays = ref<RemainingDays | null>(null);
    const error = ref("");
    const sslPort = ref("443");
    const sslPortOptions = ref([
      { value: "443", label: "443", description: "", disabled: false },
      { value: "8443", label: "8443", description: "", disabled: false },
    ]);
    const chain = ref<Pem[]>([]);
    const verification = ref(null);
    const sanEntries = ref<string[]>([]);
    const cn = ref("");

    const loading = ref(false);

    // Define i18n
    const { t, locale } = useI18n();

    // Add new error flags
    const invalidInput = ref(false);
    const unreachableHost = ref(false);

    // Define computed properties
    const isValidHostnameOrIp = computed(() => {
      return validator.isFQDN(hostname.value) || validator.isIP(hostname.value);
    });

    const getAlertMessage = computed(() => {
      console.log("Computing getAlertMessage");
      if (invalidInput.value) {
        console.log("Invalid input detected");
        return t("tools.ssl.certLookup.messages.hostNotValid");
      }
      if (unreachableHost.value) {
        console.log("Unreachable host detected");
        return t("tools.ssl.certLookup.messages.hostNotReachable");
      }
      console.log("No alert message");
      return "";
    });

    const alertClass = computed(() => {
      if (hostname.value !== "" && !isValidHostnameOrIp.value) {
        return "alert-warning";
      }
      if (hostname.value !== "" && !ipAddress.value) {
        return "alert-danger";
      }
      return "";
    });

    const darkTheme = inject<Ref<boolean>>("darkTheme", ref(false));
    if (!darkTheme.value) {
      provide("darkTheme", ref(false));
    }

    const logoPaths = computed(() => {
      const theme = darkTheme.value ? "dark" : "light";
      return {
        gws: require(`@/assets/images/servertypeLogos/gws_${theme}.png`),
        varnish: require(`@/assets/images/servertypeLogos/Varnish_${theme}.png`),
        cloudflare: require(`@/assets/images/servertypeLogos/cloudflare_${theme}.png`),
        nginx: require("@/assets/images/servertypeLogos/nginx.png"),
        apache: require("@/assets/images/servertypeLogos/apache.png"),
      };
    });

    const serverTypeLogoPath = computed(() => {
      const serverTypeLower =
        serverType.value.toLowerCase() as keyof typeof logoPaths.value;
      return logoPaths.value[serverTypeLower] || "";
    });

    const isLastChainMemberRoot = computed(() => {
      const lastChainMember = chain.value[chain.value.length - 1];
      const upperCommonName = (lastChainMember.subjectCN || "").toUpperCase();
      const upperOrganization = (
        lastChainMember.subjectOrganization || ""
      ).toUpperCase();
      return (
        upperCommonName.includes("ROOT") || upperOrganization.includes("ROOT")
      );
    });

    // Define functions
    const certLookup = debounce(async function () {
      loading.value = true; // Set loading state to true
      //await new Promise((resolve) => setTimeout(resolve, 2000));
      // Reset values
      error.value = "";
      ipAddress.value = "";
      serverType.value = "";
      isHostnameInCert.value = null;
      chain.value = [];
      verification.value = null;
      sanEntries.value = [];
      cn.value = "";

      // Check if hostname or IP is valid
      if (!isValidHostnameOrIp.value) {
        error.value = "Invalid hostname or IP address";
        invalidInput.value = true;
        return;
      }

      // Fetch data from API
      try {
        const baseUrl = process.env.VUE_APP_API_URL;
        const apiUrl = `${baseUrl}/ssl/certLookup`;

        const res = await fetch(apiUrl, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            hostname: hostname.value,
            sslPort: sslPort.value,
          }),
        });

        if (!res.ok) {
          unreachableHost.value = true;
          return;
        }

        const data = await res.json();

        if (data.error) {
          error.value = data.error;
        } else {
          // Update values with data from API
          ipAddress.value = data.ipAddress;
          serverType.value = data.serverType;
          isHostnameInCert.value = data.isHostnameInCert;
          certValidFromDate.value = data.certValidFromDate;
          certValidToDate.value = data.certValidToDate;
          remainingDays.value = calculateRemainingDays(data.certValidToDate, t);
          chain.value = data.chain.map((pem: Pem) => {
            let crtTyp = "";
            let commonName = pem.subjectCN || "";
            const subjectAltName = data.sanEntries;
            const upperCommonName = commonName.toUpperCase();
            const upperOrganization = (
              pem.subjectOrganization || ""
            ).toUpperCase();

            if (
              commonName.includes("*.") &&
              subjectAltName.some((entry: string) => entry.includes("*."))
            ) {
              crtTyp = t("tools.ssl.general.certTypes.wildcard");
            } else if (commonName.includes(".") && !commonName.includes(" ")) {
              crtTyp = t("tools.ssl.general.certTypes.main");
            } else if (!commonName.includes(".")) {
              if (
                !upperCommonName.includes("LOCALHOST") &&
                !upperCommonName.includes("ROOT") &&
                !upperOrganization.includes("ROOT")
              ) {
                crtTyp = t("tools.ssl.general.certTypes.intermediate");
              } else if (
                upperCommonName.includes("ROOT") ||
                upperOrganization.includes("ROOT")
              ) {
                crtTyp = t("tools.ssl.general.certTypes.root");
              }
            }

            return {
              ...pem,
              certType: crtTyp,
              validFrom: pem.validFrom,
              validTo: pem.validTo,
            };
          });
          verification.value = data.verification;
          sanEntries.value = data.sanEntries;
          cn.value = data.cn;
        }
      } catch (err) {
        if (err instanceof Error) {
          error.value = err.message;
        } else {
          error.value = "An error occurred";
        }
      }
      loading.value = false;
    }, 1000);

    // Watchers for input sanitization
    watch(hostname, (newValue) => {
      const sanitizedValue = newValue.replace(/[^a-zA-Z0-9.-]/g, ""); // Remove invalid characters
      if (sanitizedValue !== newValue) {
        hostname.value = sanitizedValue;
      }
      invalidInput.value = false;
      unreachableHost.value = false;
      certLookup(); // Call certLookup function
    });

    watch(sslPort, (newValue) => {
      const sanitizedValue = String(newValue).replace(/[^0-9]/g, ""); // Convert to string and remove non-numeric characters
      if (sanitizedValue !== String(newValue)) {
        // Convert newValue to string for comparison
        sslPort.value = sanitizedValue;
      }
      invalidInput.value = false;
      unreachableHost.value = false;
      certLookup(); // Call certLookup function
    });

    // Return values for template
    return {
      t,
      locale,
      hostname,
      ipAddress,
      serverType,
      isHostnameInCert,
      certValidToDate,
      certValidFromDate,
      error,
      sslPort,
      sslPortOptions,
      isValidHostnameOrIp,
      formatDate,
      chain,
      verification,
      remainingDays,
      sanEntries,
      cn,
      serverTypeLogoPath,
      isLastChainMemberRoot,
      certLookup,
      loading,
      getAlertMessage,
      alertClass,
      FontAwesomeIcon,
      invalidInput,
      unreachableHost,
    };
  },
};
</script>

<style scoped>
textarea {
  margin-top: 10px;
}
.serverTypeLogo {
  margin-left: 20px;
}
</style>
