import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppHeader = _resolveComponent("AppHeader")!
  const _component_AppInput = _resolveComponent("AppInput")!
  const _component_AppSelectInput = _resolveComponent("AppSelectInput")!
  const _component_AppButton = _resolveComponent("AppButton")!
  const _component_AppInputGroup = _resolveComponent("AppInputGroup")!
  const _component_AppAccordionItem = _resolveComponent("AppAccordionItem")!
  const _component_AppTextarea = _resolveComponent("AppTextarea")!
  const _component_AppAccordion = _resolveComponent("AppAccordion")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_AppHeader, {
      title: _ctx.t('tools.misc.uuidGenerator.title'),
      description: _ctx.t('tools.misc.uuidGenerator.description'),
      icon: ['fab', 'linux'],
      iconType: "fontawesome"
    }, null, 8, ["title", "description"]),
    _createVNode(_component_AppAccordion, null, {
      default: _withCtx(() => [
        _createVNode(_component_AppAccordionItem, {
          alwaysOpen: "",
          title: "UUID",
          icon: ['fas', 'key'],
          collapseId: "UUIDPanel-collapse",
          headerId: "UUIDPanel-header"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_AppInputGroup, null, {
              default: _withCtx(() => [
                _createVNode(_component_AppInput, {
                  class: "form-control-lg font-monospace",
                  value: _ctx.currentUUID,
                  onClick: _ctx.selectValue,
                  readonly: true
                }, null, 8, ["value", "onClick"]),
                _createVNode(_component_AppSelectInput, {
                  style: {"width":"270px !important","max-width":"270px !important"},
                  id: "uuid-version",
                  modelValue: _ctx.selectedVersion,
                  options: _ctx.uuidVersionOptions,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.selectedVersion = $event))
                }, null, 8, ["modelValue", "options"]),
                _createVNode(_component_AppButton, {
                  btnClass: "btn btn-secondary",
                  icon: ['fas', 'rotate'],
                  title: "Reload",
                  disabled: _ctx.selectedVersion === 3 || _ctx.selectedVersion === 5,
                  onClick: _ctx.onReload
                }, null, 8, ["disabled", "onClick"]),
                _createVNode(_component_AppButton, {
                  btnClass: "btn btn-secondary",
                  icon: ['fas', 'copy'],
                  title: "Copy",
                  disabled: _ctx.currentUUID === 'xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx',
                  onClick: _ctx.copyToClipboard
                }, null, 8, ["disabled", "onClick"])
              ]),
              _: 1
            }),
            (_ctx.selectedVersion === 3 || _ctx.selectedVersion === 5)
              ? (_openBlock(), _createBlock(_component_AppInputGroup, {
                  key: 0,
                  class: "mt-2"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_AppSelectInput, {
                      style: {"width":"270px !important","max-width":"270px !important"},
                      id: "uuid-identifier",
                      modelValue: _ctx.selectedIdentifier,
                      options: _ctx.uuidIdentifierOptions,
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.selectedIdentifier = $event))
                    }, null, 8, ["modelValue", "options"]),
                    (_ctx.selectedIdentifier === 'custom')
                      ? (_openBlock(), _createBlock(_component_AppInput, {
                          key: 0,
                          class: "form-control-lg font-monospace",
                          modelValue: _ctx.customIdentifier,
                          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.customIdentifier) = $event)),
                          placeholder: "00000000-0000-0000-0000-000000000000"
                        }, null, 8, ["modelValue"]))
                      : _createCommentVNode("", true),
                    _createVNode(_component_AppInput, {
                      class: "form-control-lg font-monospace",
                      modelValue: _ctx.identifierName,
                      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.identifierName) = $event)),
                      placeholder: "Name (any value)"
                    }, null, 8, ["modelValue"])
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }),
        (_ctx.selectedVersion !== 3 && _ctx.selectedVersion !== 5)
          ? (_openBlock(), _createBlock(_component_AppAccordionItem, {
              key: 0,
              title: _ctx.t('tools.misc.uuidGenerator.more.title'),
              icon: ['fas', 'list-ol'],
              collapseId: "moreUuidsPanel-collapse",
              headerId: "moreUuidsPanel-header",
              isNew: ""
            }, {
              default: _withCtx(() => [
                _createVNode(_component_AppTextarea, {
                  readonly: true,
                  class: "form-control font-monospace",
                  modelValue: _ctx.uuidList,
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.uuidList) = $event))
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }, 8, ["title"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ], 64))
}