import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = { class: "accordion-item" }
const _hoisted_2 = ["id"]
const _hoisted_3 = ["data-bs-target", "aria-expanded", "aria-controls"]
const _hoisted_4 = {
  key: 0,
  class: "badge rounded-pill text-bg-success ms-2"
}
const _hoisted_5 = ["id", "aria-labelledby"]
const _hoisted_6 = { class: "accordion-body" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h2", {
      class: "accordion-header",
      id: $props.headerId
    }, [
      _createElementVNode("button", {
        class: _normalizeClass(["accordion-button", { collapsed: !$props.alwaysOpen, 'visually-hidden': $props.alwaysOpen }]),
        type: "button",
        "data-bs-target": `#${$props.collapseId}`,
        "data-bs-toggle": "collapse",
        "aria-expanded": $props.alwaysOpen ? 'true' : 'false',
        "aria-controls": $props.collapseId
      }, [
        _createVNode(_component_font_awesome_icon, {
          class: "pe-2",
          icon: $props.icon
        }, null, 8, ["icon"]),
        _createTextVNode(" " + _toDisplayString($props.title) + " ", 1),
        ($props.isNew)
          ? (_openBlock(), _createElementBlock("span", _hoisted_4, "NEW"))
          : _createCommentVNode("", true)
      ], 10, _hoisted_3)
    ], 8, _hoisted_2),
    _createElementVNode("div", {
      id: $props.collapseId,
      class: _normalizeClass(["accordion-collapse collapse", { show: $props.alwaysOpen }]),
      "aria-labelledby": $props.headerId
    }, [
      _createElementVNode("div", _hoisted_6, [
        _renderSlot(_ctx.$slots, "default")
      ])
    ], 10, _hoisted_5)
  ]))
}