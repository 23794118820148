<template>
  <div class="container">
    <h1 class="my-5">{{ $t("Home") }}</h1>
    <p>This is the Home page.</p>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "HomeView",
});
</script>
