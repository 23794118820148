<template>
  <!-- Header -->
  <AppHeader
    :title="t('tools.ssl.certKeyMatcher.title')"
    :description="t('tools.ssl.certKeyMatcher.description')"
    :icon="['fas', 'not-equal']"
    iconType="fontawesome"
  />

  <AppContent>
    <!-- Textareas within a Bootstrap Grid -->
    <div class="row g-3">
      <!-- Certificate input -->
      <div class="col-xl-6">
        <AppTextarea
          :value="certificate"
          @update:modelValue="certificate = $event"
          spellcheck="false"
          placeholder="-----BEGIN CERTIFICATE-----
MIID0DCCArigAwIBAgIBATANBgkqhkiG9w0BAQUFADB/MQswCQYDVQQGEwJGUjET
vepIxc/1aHZrG+dPeEHt0MdFfOw13YdUc2FH6AqEdcEL4aV5PXq2eYR8hR4zKbc1
fBtuqUsvA8NWSIyzQ16fyGve+ANf6vXvUizyvwDrPRv/kfvLNa3ZPnLMMxU98Mvh
PXy3PkB8++6U4Y3vdk2Ni2WYYlIls8yqbM4327IKmkDc2TimS8u60CT47mKU7aDY
cbTV5RDkrlaYwm5yqlTIglvCv7o=
-----END CERTIFICATE-----"
        />
      </div>

      <!-- Key or CSR input -->
      <div class="col-xl-6">
        <AppTextarea
          :value="keyOrCsr"
          @update:modelValue="keyOrCsr = $event"
          spellcheck="false"
          placeholder="-----BEGIN PRIVATE KEY-----
MIIEowIBAAKCAQEAvpnaPKLIKdvx98KW68lz8pGaRRcYersNGqPjpifMVjjE8LuC
CdLygS5RtgOLZib0IVErqWsP3EI1ACGuLts0vQ9GFLQGaN1SaMS40C9kvns1mlDu
LhIhYpJ8UsCVt5snWo2N+M+6ANh5tpWdQnEK6zILh4tRbuzaiHgb
-----END PRIVATE KEY-----
or
-----BEGIN CERTIFICATE REQUEST-----
MIICvDCCAaQCAQAwdzELMAkGA1UEBhMCVVMxDTALBgNVBAgMBFV0YWgxDzANBgNV
29XI1PpVUNCPQGn9p/eX6Qo7vpDaPybRtA2R7XLKjQaF9oXWeCUqy1hvJac9QFO2
97Ob1alpHPoZ7mWiEuJwjBPii6a9M9G30nUo39lBi1w=
-----END CERTIFICATE REQUEST-----
"
        />
      </div>
    </div>
  </AppContent>

  <!-- Error messages -->
  <div v-if="showResults && matchingError" class="alert alert-danger mt-3">
    {{ matchingError }}
  </div>

  <!-- Match result output -->
  <div v-if="showResults && matchResult">
    <div
      class="alert mt-3"
      :class="matchResult.Matches ? 'alert-success' : 'alert-danger'"
    >
      {{ matchResult.message }}
    </div>
  </div>
</template>

<script lang="ts">
import { ref, watch, computed } from "vue";
import { debounce } from "@/utils/global/debounce";
import {
  isValidCert,
  isValidKey,
  isValidCsr,
} from "@/utils/ssl/pemFormatValidation";

import AppHeader from "@/components/card/AppHeader.vue";
import AppContent from "@/components/card/AppContent.vue";
import AppTextarea from "@/components/textarea/AppTextarea.vue";
import { useI18n } from "vue-i18n";

export default {
  components: {
    AppHeader,
    AppContent,
    AppTextarea,
  },
  setup() {
    const certificate = ref("");
    const keyOrCsr = ref("");
    const matchingError = ref("");
    const matchResult = ref<{ Matches: boolean; message: string } | null>(null);
    const { t } = useI18n();

    function isValidKeyOrCsr(value: string): boolean {
      return isValidKey(value.trim()) || isValidCsr(value.trim());
    }

    watch([certificate, keyOrCsr], ([newCert, newKeyOrCsr]) => {
      // Reset the error and match results when content changes
      matchingError.value = "";
      matchResult.value = null;

      // If the textareas are empty, simply return to prevent showing error messages
      if (newCert.trim() === "" && newKeyOrCsr.trim() === "") {
        return;
      }

      const certValid = isValidCert(newCert);
      const keyOrCsrValid = isValidKeyOrCsr(newKeyOrCsr);

      if (newCert.trim() === "" && keyOrCsrValid) {
        // If only key/CSR is filled and valid, no error
        return;
      }

      if (newKeyOrCsr.trim() === "" && certValid) {
        // If only certificate is filled and valid, no error
        return;
      }

      if (!certValid && !keyOrCsrValid) {
        // If both are invalid, show a combined error message
        matchingError.value =
          "No valid PEM-formatted certificate and key or csr found.";
      } else if (!certValid) {
        // If only certificate is invalid, show a specific error message
        matchingError.value = "No valid PEM-formatted certificate found.";
      } else if (!keyOrCsrValid) {
        // If only key or CSR is invalid, show a specific error message
        matchingError.value = "No valid PEM-formatted key or csr found.";
      } else {
        // If both are valid, try to check their match
        checkMatch();
      }
    });

    const checkMatch = debounce(async function () {
      matchingError.value = "";

      const baseUrl = process.env.VUE_APP_API_URL;
      const apiUrl = `${baseUrl}/ssl/certKeyMatcher`;

      const isKey =
        isValidKeyOrCsr(keyOrCsr.value) &&
        !keyOrCsr.value.includes("CERTIFICATE REQUEST");
      const isCsr =
        isValidKeyOrCsr(keyOrCsr.value) &&
        keyOrCsr.value.includes("CERTIFICATE REQUEST");

      const res = await fetch(apiUrl, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          certificate: certificate.value,
          key: isKey ? keyOrCsr.value : null,
          csr: isCsr ? keyOrCsr.value : null,
        }),
      });

      const data = await res.json();

      // Determine the appropriate match message
      const MATCH_SUCCESS = isKey
        ? "The certificate and KEY match"
        : "The certificate and CSR match";
      const MATCH_ERROR = isKey
        ? "The certificate and KEY do not match"
        : "The certificate and CSR do not match";

      matchResult.value = {
        Matches: data.Matches,
        message: data.Matches ? MATCH_SUCCESS : MATCH_ERROR,
      };
    }, 1000);

    const showResults = computed(
      () => !!matchingError.value || !!matchResult.value
    );

    return {
      certificate,
      keyOrCsr,
      matchingError,
      matchResult,
      showResults,
      t,
    };
  },
};
</script>

<style scoped>
.form-control {
  font-size: 14px;
}
</style>
