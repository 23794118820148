import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = {
  key: 1,
  class: "alert alert-danger"
}
const _hoisted_3 = { key: 2 }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { class: "col-3 text-end fw-bold table-desc" }
const _hoisted_6 = ["innerHTML"]
const _hoisted_7 = { key: 1 }
const _hoisted_8 = { class: "col-3 text-end fw-bold table-desc" }
const _hoisted_9 = { class: "col-3 text-end fw-bold table-desc" }
const _hoisted_10 = { key: 0 }
const _hoisted_11 = { key: 1 }
const _hoisted_12 = { class: "float-end small" }
const _hoisted_13 = { key: 2 }
const _hoisted_14 = { class: "col-3 text-end fw-bold table-desc" }
const _hoisted_15 = { key: 3 }
const _hoisted_16 = { class: "col-3 text-end fw-bold table-desc" }
const _hoisted_17 = { key: 4 }
const _hoisted_18 = { class: "col-3 text-end fw-bold table-desc" }
const _hoisted_19 = { key: 5 }
const _hoisted_20 = { class: "col-3 text-end fw-bold table-desc" }
const _hoisted_21 = { key: 6 }
const _hoisted_22 = { class: "col-3 text-end fw-bold table-desc" }
const _hoisted_23 = { key: 7 }
const _hoisted_24 = { class: "col-3 text-end fw-bold table-desc" }
const _hoisted_25 = { class: "col-3 text-end fw-bold table-desc" }
const _hoisted_26 = { class: "float-end small" }
const _hoisted_27 = { class: "col-3 text-end fw-bold table-desc" }
const _hoisted_28 = { class: "float-end small" }
const _hoisted_29 = { key: 0 }
const _hoisted_30 = { class: "col-3 text-end fw-bold table-desc" }
const _hoisted_31 = { key: 1 }
const _hoisted_32 = { class: "col-3 text-end fw-bold table-desc" }
const _hoisted_33 = { key: 2 }
const _hoisted_34 = { class: "col-3 text-end fw-bold table-desc" }
const _hoisted_35 = { key: 3 }
const _hoisted_36 = { class: "col-3 text-end fw-bold table-desc" }
const _hoisted_37 = { key: 4 }
const _hoisted_38 = { class: "col-3 text-end fw-bold table-desc" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppHeader = _resolveComponent("AppHeader")!
  const _component_AppTextarea = _resolveComponent("AppTextarea")!
  const _component_AppContent = _resolveComponent("AppContent")!
  const _component_AppTable = _resolveComponent("AppTable")!
  const _component_AppAccordionItem = _resolveComponent("AppAccordionItem")!
  const _component_AppAccordion = _resolveComponent("AppAccordion")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_AppHeader, {
      title: $setup.t('tools.ssl.crtDecoder.title'),
      description: $setup.t('tools.ssl.crtDecoder.description'),
      icon: ['fab', 'expeditedssl'],
      iconType: "fontawesome"
    }, null, 8, ["title", "description"]),
    _createVNode(_component_AppContent, null, {
      default: _withCtx(() => [
        _createVNode(_component_AppTextarea, {
          value: $setup.certificates,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => {
        $setup.certificates = $event;
        $setup.decodeCertificates();
      }),
          placeholder: "-----BEGIN CERTIFICATE-----\nMIICgjCCAjSgAwIBAgITB2Y8zXRHikdU9jKPM22+7kcZXTAFBgMrZXAwNTEzMDEG\nA1UEAxMqU2FtcGxlIExBTVBTIEVkMjU1MTkgQ2VydGlmaWNhdGUgQXV0aG9yaXR5\n3ZW1UrhK5Pb9qSL4gizDZ7ZaGZNudwjJu20HHVIGQT7nDwIDAQABo2MwYTAPBgNV\nHRMBAf8EBTADAQH/MA4GA1UdDwEB/wQEAwIBBjAdBgNVHQ4EFgQUeF8OWnjYa+RU\ncD2z3ez38fL6wEcwHwYDVR0jBBgwFoAUa6KVfboUm+QtBNEHpNGC5C5rjLUwBQYD\nK2VwA0EA+Zb/X/6jcMIBDyy3UbV+8JMfYgSZRNyyyaW8Oz1dqQGtWsW2Rl0FZfw5\nfUMzFTd/jLQdU/g3LCtyIhuTHPSdAQ==\n-----END CERTIFICATE-----"
        }, null, 8, ["value"])
      ]),
      _: 1
    }),
    ($setup.error)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString($setup.error), 1))
      : _createCommentVNode("", true),
    ($setup.decodingError)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString($setup.decodingError), 1))
      : _createCommentVNode("", true),
    ($setup.processedCertificates.length)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.processedCertificates, (cert, index) => {
            return (_openBlock(), _createElementBlock("div", {
              key: 'cert-' + index
            }, [
              _createVNode(_component_AppAccordion, null, {
                default: _withCtx(() => [
                  _createVNode(_component_AppAccordionItem, {
                    alwaysOpen: "",
                    title: "Certificate",
                    icon: ['fas', 'key'],
                    collapseId: "certificateDetailsPanel-collapse",
                    headerId: "certificateDetailsPanel-header",
                    class: "no-padding-accordion"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_AppTable, null, {
                        default: _withCtx(() => [
                          (cert.commonName)
                            ? (_openBlock(), _createElementBlock("tr", _hoisted_4, [
                                _createElementVNode("td", _hoisted_5, _toDisplayString($setup.t("tools.ssl.general.cn")), 1),
                                _createElementVNode("td", {
                                  innerHTML: cert.commonName
                                }, null, 8, _hoisted_6)
                              ]))
                            : _createCommentVNode("", true),
                          (cert.sans)
                            ? (_openBlock(), _createElementBlock("tr", _hoisted_7, [
                                _createElementVNode("td", _hoisted_8, _toDisplayString($setup.t("tools.ssl.general.san")), 1),
                                _createElementVNode("td", null, _toDisplayString(cert.sans), 1)
                              ]))
                            : _createCommentVNode("", true),
                          _createElementVNode("tr", null, [
                            _createElementVNode("td", _hoisted_9, _toDisplayString($setup.t("tools.ssl.general.validFrom")), 1),
                            _createElementVNode("td", null, _toDisplayString(cert.validFrom), 1)
                          ]),
                          _createElementVNode("tr", {
                            class: _normalizeClass(cert.validState.cssStatus)
                          }, [
                            _createElementVNode("td", {
                              class: _normalizeClass(["col-3 text-end fw-bold table-desc", cert.validState.cssStatus])
                            }, _toDisplayString($setup.t("tools.ssl.general.validTo")), 3),
                            _createElementVNode("td", null, [
                              _createTextVNode(_toDisplayString(cert.validTo) + " ", 1),
                              (cert.validState.remainingDays > 0)
                                ? (_openBlock(), _createElementBlock("span", _hoisted_10, " (" + _toDisplayString(cert.validState.remainingDays) + " " + _toDisplayString($setup.t("tools.ssl.general.daysRemaining")) + ") ", 1))
                                : (_openBlock(), _createElementBlock("span", _hoisted_11, " (" + _toDisplayString(-cert.validState.remainingDays) + " " + _toDisplayString($setup.t("tools.ssl.general.daysOverIt")) + ") ", 1)),
                              _createElementVNode("span", _hoisted_12, _toDisplayString(cert.validState.note), 1)
                            ])
                          ], 2),
                          (cert.organization)
                            ? (_openBlock(), _createElementBlock("tr", _hoisted_13, [
                                _createElementVNode("td", _hoisted_14, _toDisplayString($setup.t("tools.ssl.general.organization")), 1),
                                _createElementVNode("td", null, _toDisplayString(cert.organization), 1)
                              ]))
                            : _createCommentVNode("", true),
                          (cert.organizationUnit)
                            ? (_openBlock(), _createElementBlock("tr", _hoisted_15, [
                                _createElementVNode("td", _hoisted_16, _toDisplayString($setup.t("tools.ssl.general.organizationUnit")), 1),
                                _createElementVNode("td", null, _toDisplayString(cert.organizationUnit), 1)
                              ]))
                            : _createCommentVNode("", true),
                          (cert.locality)
                            ? (_openBlock(), _createElementBlock("tr", _hoisted_17, [
                                _createElementVNode("td", _hoisted_18, _toDisplayString($setup.t("tools.ssl.general.locality")), 1),
                                _createElementVNode("td", null, _toDisplayString(cert.locality), 1)
                              ]))
                            : _createCommentVNode("", true),
                          (cert.state)
                            ? (_openBlock(), _createElementBlock("tr", _hoisted_19, [
                                _createElementVNode("td", _hoisted_20, _toDisplayString($setup.t("tools.ssl.general.state")), 1),
                                _createElementVNode("td", null, _toDisplayString(cert.state), 1)
                              ]))
                            : _createCommentVNode("", true),
                          (cert.country)
                            ? (_openBlock(), _createElementBlock("tr", _hoisted_21, [
                                _createElementVNode("td", _hoisted_22, _toDisplayString($setup.t("tools.ssl.general.country")), 1),
                                _createElementVNode("td", null, _toDisplayString(cert.country), 1)
                              ]))
                            : _createCommentVNode("", true),
                          ($setup.issuerValues[index] !== 'N/A (N/A)')
                            ? (_openBlock(), _createElementBlock("tr", _hoisted_23, [
                                _createElementVNode("td", _hoisted_24, _toDisplayString($setup.t("tools.ssl.crtDecoder.issuer")), 1),
                                _createElementVNode("td", null, _toDisplayString($setup.issuerValues[index]), 1)
                              ]))
                            : _createCommentVNode("", true),
                          (cert.publicKeySize)
                            ? (_openBlock(), _createElementBlock("tr", {
                                key: 8,
                                class: _normalizeClass(cert.publicKeySecurity.cssStatus)
                              }, [
                                _createElementVNode("td", _hoisted_25, _toDisplayString($setup.t("tools.ssl.general.pubKeySize")), 1),
                                _createElementVNode("td", null, [
                                  _createTextVNode(_toDisplayString(cert.publicKeySize) + " bit (" + _toDisplayString(cert.publicKeyAlgorithm) + ") ", 1),
                                  _createElementVNode("span", _hoisted_26, _toDisplayString(cert.publicKeySecurity.note), 1)
                                ])
                              ], 2))
                            : _createCommentVNode("", true),
                          (cert.signature)
                            ? (_openBlock(), _createElementBlock("tr", {
                                key: 9,
                                class: _normalizeClass(cert.signatureSecurity.cssStatus)
                              }, [
                                _createElementVNode("td", _hoisted_27, _toDisplayString($setup.t("tools.ssl.general.signatureAlgorithm")), 1),
                                _createElementVNode("td", null, [
                                  _createTextVNode(_toDisplayString(cert.signatureAlgorithmSN) + " (" + _toDisplayString(cert.signature) + ") ", 1),
                                  _createElementVNode("span", _hoisted_28, _toDisplayString(cert.signatureSecurity.note), 1)
                                ])
                              ], 2))
                            : _createCommentVNode("", true)
                        ]),
                        _: 2
                      }, 1024)
                    ]),
                    _: 2
                  }, 1024),
                  _createVNode(_component_AppAccordionItem, {
                    title: $setup.t('tools.ssl.general.additionalDetails'),
                    icon: ['fas', 'list-ol'],
                    collapseId: 'additionalDetailsPanel-collapse-' + index,
                    headerId: 'additionalDetailsPanel-header-' + index,
                    class: "no-padding-accordion"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_AppTable, null, {
                        default: _withCtx(() => [
                          (cert.serial)
                            ? (_openBlock(), _createElementBlock("tr", _hoisted_29, [
                                _createElementVNode("td", _hoisted_30, _toDisplayString($setup.t("tools.ssl.crtDecoder.serialNumber")), 1),
                                _createElementVNode("td", null, _toDisplayString(cert.serial), 1)
                              ]))
                            : _createCommentVNode("", true),
                          (cert.sha1Fingerprint)
                            ? (_openBlock(), _createElementBlock("tr", _hoisted_31, [
                                _createElementVNode("td", _hoisted_32, _toDisplayString($setup.t("tools.ssl.general.sha1Fingerprint")), 1),
                                _createElementVNode("td", null, _toDisplayString(cert.sha1Fingerprint), 1)
                              ]))
                            : _createCommentVNode("", true),
                          (cert.md5Fingerprint)
                            ? (_openBlock(), _createElementBlock("tr", _hoisted_33, [
                                _createElementVNode("td", _hoisted_34, _toDisplayString($setup.t("tools.ssl.general.md5Fingerprint")), 1),
                                _createElementVNode("td", null, _toDisplayString(cert.md5Fingerprint), 1)
                              ]))
                            : _createCommentVNode("", true),
                          (cert.subjectKeyIdentifier)
                            ? (_openBlock(), _createElementBlock("tr", _hoisted_35, [
                                _createElementVNode("td", _hoisted_36, _toDisplayString($setup.t("tools.ssl.crtDecoder.subjectKeyIdentifier")), 1),
                                _createElementVNode("td", null, _toDisplayString(cert.subjectKeyIdentifier), 1)
                              ]))
                            : _createCommentVNode("", true),
                          (cert.authorityKeyIdentifier)
                            ? (_openBlock(), _createElementBlock("tr", _hoisted_37, [
                                _createElementVNode("td", _hoisted_38, _toDisplayString($setup.t("tools.ssl.crtDecoder.authorityKeyIdentifier")), 1),
                                _createElementVNode("td", null, _toDisplayString(cert.authorityKeyIdentifier), 1)
                              ]))
                            : _createCommentVNode("", true)
                        ]),
                        _: 2
                      }, 1024)
                    ]),
                    _: 2
                  }, 1032, ["title", "collapseId", "headerId"])
                ]),
                _: 2
              }, 1024)
            ]))
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ], 64))
}