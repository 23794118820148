<template>
  <AppContent>
    <div id="loading" class="text-center pt-3">
      <div class="spinner-border text-warning mt-3 mb-4"></div>
      <h5 class="pb-3">{{ t("general.messages.loading") }}</h5>
    </div>
  </AppContent>
</template>

<script lang="ts">
// Importing components
import AppContent from "@/components/card/AppContent.vue";

// Importing locales
import { useI18n } from "vue-i18n";

export default {
  components: {
    AppContent,
  },
  setup() {
    const i18n = useI18n();
    const { t } = i18n;
    return {
      t,
    };
  },
};
</script>
