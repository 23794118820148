import { openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["value", "placeholder", "rows", "disabled", "readonly", "maxlength"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("textarea", {
    value: _ctx.modelValue,
    placeholder: _ctx.placeholder,
    rows: _ctx.rows,
    disabled: _ctx.disabled,
    readonly: _ctx.readonly,
    maxlength: _ctx.maxlength,
    onInput: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.updateValue && _ctx.updateValue(...args))),
    class: "form-control"
  }, null, 40, _hoisted_1))
}