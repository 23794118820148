<template>
  <!-- Header -->
  <AppHeader
    :title="t('tools.ssl.crtDecoder.title')"
    :description="t('tools.ssl.crtDecoder.description')"
    :icon="['fab', 'expeditedssl']"
    iconType="fontawesome"
  />

  <!-- Certificate input -->
  <AppContent>
    <AppTextarea
      :value="certificates"
      @update:modelValue="
        certificates = $event;
        decodeCertificates();
      "
      placeholder="-----BEGIN CERTIFICATE-----
MIICgjCCAjSgAwIBAgITB2Y8zXRHikdU9jKPM22+7kcZXTAFBgMrZXAwNTEzMDEG
A1UEAxMqU2FtcGxlIExBTVBTIEVkMjU1MTkgQ2VydGlmaWNhdGUgQXV0aG9yaXR5
3ZW1UrhK5Pb9qSL4gizDZ7ZaGZNudwjJu20HHVIGQT7nDwIDAQABo2MwYTAPBgNV
HRMBAf8EBTADAQH/MA4GA1UdDwEB/wQEAwIBBjAdBgNVHQ4EFgQUeF8OWnjYa+RU
cD2z3ez38fL6wEcwHwYDVR0jBBgwFoAUa6KVfboUm+QtBNEHpNGC5C5rjLUwBQYD
K2VwA0EA+Zb/X/6jcMIBDyy3UbV+8JMfYgSZRNyyyaW8Oz1dqQGtWsW2Rl0FZfw5
fUMzFTd/jLQdU/g3LCtyIhuTHPSdAQ==
-----END CERTIFICATE-----"
    />
  </AppContent>

  <!-- Error messages -->
  <div v-if="error">{{ error }}</div>
  <div v-if="decodingError" class="alert alert-danger">
    {{ decodingError }}
  </div>

  <!-- Certificate decoder output -->
  <div v-if="processedCertificates.length">
    <div v-for="(cert, index) in processedCertificates" :key="'cert-' + index">
      <AppAccordion>
        <AppAccordionItem
          alwaysOpen
          title="Certificate"
          :icon="['fas', 'key']"
          collapseId="certificateDetailsPanel-collapse"
          headerId="certificateDetailsPanel-header"
          class="no-padding-accordion"
        >
          <AppTable>
            <tr v-if="cert.commonName">
              <td class="col-3 text-end fw-bold table-desc">
                {{ t("tools.ssl.general.cn") }}
              </td>
              <td v-html="cert.commonName"></td>
            </tr>
            <tr v-if="cert.sans">
              <td class="col-3 text-end fw-bold table-desc">
                {{ t("tools.ssl.general.san") }}
              </td>
              <td>{{ cert.sans }}</td>
            </tr>
            <tr>
              <td class="col-3 text-end fw-bold table-desc">
                {{ t("tools.ssl.general.validFrom") }}
              </td>
              <td>{{ cert.validFrom }}</td>
            </tr>
            <tr :class="cert.validState.cssStatus">
              <td
                class="col-3 text-end fw-bold table-desc"
                :class="cert.validState.cssStatus"
              >
                {{ t("tools.ssl.general.validTo") }}
              </td>
              <td>
                {{ cert.validTo }}
                <span v-if="cert.validState.remainingDays > 0">
                  ({{ cert.validState.remainingDays }}
                  {{ t("tools.ssl.general.daysRemaining") }})
                </span>
                <span v-else>
                  ({{ -cert.validState.remainingDays }}
                  {{ t("tools.ssl.general.daysOverIt") }})
                </span>
                <span class="float-end small">
                  {{ cert.validState.note }}
                </span>
              </td>
            </tr>
            <tr v-if="cert.organization">
              <td class="col-3 text-end fw-bold table-desc">
                {{ t("tools.ssl.general.organization") }}
              </td>
              <td>{{ cert.organization }}</td>
            </tr>
            <tr v-if="cert.organizationUnit">
              <td class="col-3 text-end fw-bold table-desc">
                {{ t("tools.ssl.general.organizationUnit") }}
              </td>
              <td>{{ cert.organizationUnit }}</td>
            </tr>
            <tr v-if="cert.locality">
              <td class="col-3 text-end fw-bold table-desc">
                {{ t("tools.ssl.general.locality") }}
              </td>
              <td>{{ cert.locality }}</td>
            </tr>
            <tr v-if="cert.state">
              <td class="col-3 text-end fw-bold table-desc">
                {{ t("tools.ssl.general.state") }}
              </td>
              <td>{{ cert.state }}</td>
            </tr>
            <tr v-if="cert.country">
              <td class="col-3 text-end fw-bold table-desc">
                {{ t("tools.ssl.general.country") }}
              </td>
              <td>{{ cert.country }}</td>
            </tr>
            <tr v-if="issuerValues[index] !== 'N/A (N/A)'">
              <td class="col-3 text-end fw-bold table-desc">
                {{ t("tools.ssl.crtDecoder.issuer") }}
              </td>
              <td>{{ issuerValues[index] }}</td>
            </tr>
            <tr
              v-if="cert.publicKeySize"
              :class="cert.publicKeySecurity.cssStatus"
            >
              <td class="col-3 text-end fw-bold table-desc">
                {{ t("tools.ssl.general.pubKeySize") }}
              </td>
              <td>
                {{ cert.publicKeySize }} bit ({{ cert.publicKeyAlgorithm }})
                <span class="float-end small">
                  {{ cert.publicKeySecurity.note }}
                </span>
              </td>
            </tr>
            <tr v-if="cert.signature" :class="cert.signatureSecurity.cssStatus">
              <td class="col-3 text-end fw-bold table-desc">
                {{ t("tools.ssl.general.signatureAlgorithm") }}
              </td>
              <td>
                {{ cert.signatureAlgorithmSN }} ({{ cert.signature }})
                <span class="float-end small">
                  {{ cert.signatureSecurity.note }}
                </span>
              </td>
            </tr>
          </AppTable>
        </AppAccordionItem>

        <!-- Additional certificate details -->
        <AppAccordionItem
          :title="t('tools.ssl.general.additionalDetails')"
          :icon="['fas', 'list-ol']"
          :collapseId="'additionalDetailsPanel-collapse-' + index"
          :headerId="'additionalDetailsPanel-header-' + index"
          class="no-padding-accordion"
        >
          <AppTable>
            <tr v-if="cert.serial">
              <td class="col-3 text-end fw-bold table-desc">
                {{ t("tools.ssl.crtDecoder.serialNumber") }}
              </td>
              <td>{{ cert.serial }}</td>
            </tr>
            <tr v-if="cert.sha1Fingerprint">
              <td class="col-3 text-end fw-bold table-desc">
                {{ t("tools.ssl.general.sha1Fingerprint") }}
              </td>
              <td>{{ cert.sha1Fingerprint }}</td>
            </tr>
            <tr v-if="cert.md5Fingerprint">
              <td class="col-3 text-end fw-bold table-desc">
                {{ t("tools.ssl.general.md5Fingerprint") }}
              </td>
              <td>{{ cert.md5Fingerprint }}</td>
            </tr>
            <tr v-if="cert.subjectKeyIdentifier">
              <td class="col-3 text-end fw-bold table-desc">
                {{ t("tools.ssl.crtDecoder.subjectKeyIdentifier") }}
              </td>
              <td>{{ cert.subjectKeyIdentifier }}</td>
            </tr>
            <tr v-if="cert.authorityKeyIdentifier">
              <td class="col-3 text-end fw-bold table-desc">
                {{ t("tools.ssl.crtDecoder.authorityKeyIdentifier") }}
              </td>
              <td>{{ cert.authorityKeyIdentifier }}</td>
            </tr>
          </AppTable>
        </AppAccordionItem>
      </AppAccordion>
    </div>
  </div>
</template>

<script lang="ts">
// Importing modules
import { ref, onUnmounted, watch, computed } from "vue";
import { debounce } from "@/utils/global/debounce";
import { isValidCert } from "@/utils/ssl/pemFormatValidation";

// Importing components
import AppHeader from "@/components/card/AppHeader.vue";
import AppContent from "@/components/card/AppContent.vue";
import AppAccordion from "@/components/accordion/AppAccordion.vue";
import AppAccordionItem from "@/components/accordion/AppAccordionItem.vue";
import AppTextarea from "@/components/textarea/AppTextarea.vue";
import AppTable from "@/components/table/AppTable.vue";

// Importing helper
import {
  vulnerableAlgorithms,
  insecureAlgorithms,
  secureAlgorithms,
} from "../../utils/ssl/decoder/secureSigAlgorithms";

// Importing locales
import { useI18n } from "vue-i18n";

// Importing utility function
import { formatDate } from "@/utils/global/formatDate";
import { calculateRemainingDays } from "@/utils/ssl/calculateRemainingDays";

// Interface declaration
interface ValidityState {
  remainingDays: number;
  cssStatus: string;
  note: string;
}

interface PublicKeySecurity {
  cssStatus: string;
  note: string;
}

interface SignatureSecurity {
  cssStatus: string;
  note: string;
}

interface Certificate {
  issuerCN: string | null;
  issuerO: string | null;
  issuerC: string | null;
  validFrom: string;
  validTo: string;
  validState: ValidityState | null;
  commonName?: string;
  sans?: string;
  organization?: string;
  publicKeyAlgorithm: string | null;
  publicKeySize: number | null;
  signature: number | null;
  signatureAlgorithmSN: string | null;
  publicKeySecurity: PublicKeySecurity | null;
  signatureSecurity: SignatureSecurity | null;
  organizationUnit?: string;
  locality?: string;
  state?: string;
  country?: string;
  serial?: string;
  sha1Fingerprint?: string;
  md5Fingerprint?: string;
  subjectKeyIdentifier?: string;
  authorityKeyIdentifier?: string;
}

export default {
  components: {
    AppHeader,
    AppContent,
    AppAccordion,
    AppAccordionItem,
    AppTextarea,
    AppTable,
  },
  setup() {
    const certificates = ref("");
    const rawCertificates = ref<Certificate[]>([]);
    const issuerValues = ref<string[]>([]);
    const abortController = new AbortController();
    const errorValue = ref("");
    const decodingError = ref("");

    const { t, locale } = useI18n();

    const formatCertificates = (certs: Certificate[], locale: string) => {
      return certs.map((cert: Certificate) => {
        return {
          ...cert,
          validFrom: formatDate(cert.validFrom, locale),
          validTo: formatDate(cert.validTo, locale),
          validState: calculateRemainingDays(cert.validTo, t),
        };
      });
    };

    const processedCertificates = computed(() => {
      return formatCertificates(rawCertificates.value, locale.value);
    });

    const updatedCertificates = computed(() => {
      return processedCertificates.value.map((cert) => {
        let crtTyp = "";
        let commonName = cert.commonName || "";
        const subjectAltName = cert.sans || "";
        const upperCommonName = commonName.toUpperCase();
        const upperOrganization = (cert.organization || "").toUpperCase();

        if (commonName.includes("*.") || subjectAltName.includes("*.")) {
          crtTyp = t("tools.ssl.general.cert_types.wildcard");
        } else if (!commonName.includes(".")) {
          if (
            (upperCommonName.includes(" CA") ||
              upperCommonName.includes("CA ") ||
              upperCommonName.includes("CA")) &&
            !upperCommonName.includes("LOCALHOST") &&
            !upperCommonName.includes("ROOT") &&
            !upperOrganization.includes("ROOT")
          ) {
            crtTyp = t("tools.ssl.general.cert_types.intermediate");
          } else if (
            upperCommonName.includes("ROOT") ||
            upperOrganization.includes("ROOT")
          ) {
            crtTyp = t("tools.ssl.general.cert_types.root");
          } else {
            crtTyp = t("tools.ssl.general.cert_types.code_signing");
          }
        }

        let publicKeySecurity = { cssStatus: "", note: "" };

        // Check if the public key size is available and it's RSA encryption
        if (cert.publicKeyAlgorithm === "rsaEncryption") {
          if ((cert.publicKeySize ?? 0) >= 4096) {
            publicKeySecurity.cssStatus = "bg-success";
          } else if (
            (cert.publicKeySize ?? 0) < 3000 &&
            (cert.publicKeySize ?? 0) >= 2048
          ) {
            publicKeySecurity.cssStatus = "bg-warning";
            publicKeySecurity.note = t("tools.ssl.general.messages.pubKeyWeak");
          } else if ((cert.publicKeySize ?? 0) < 2048) {
            publicKeySecurity.cssStatus = "bg-danger";
            publicKeySecurity.note = t(
              "tools.ssl.general.messages.pubKeyInsecure"
            );
          }
        }

        // Check if the public key size is available and it's EC encryption
        if (cert.publicKeyAlgorithm === "id-ecPublicKey") {
          if ((cert.publicKeySize ?? 0) >= 256) {
            publicKeySecurity.cssStatus = "bg-success";
          } else {
            publicKeySecurity.cssStatus = "bg-danger";
            publicKeySecurity.note = t(
              "tools.ssl.general.messages.pubKeyInsecure"
            );
          }
        }

        let signatureSecurity = { cssStatus: "", note: "" };

        // Check the signature algorithm
        if (cert.signatureAlgorithmSN) {
          if (vulnerableAlgorithms.includes(cert.signatureAlgorithmSN)) {
            signatureSecurity.cssStatus = "bg-danger";
            signatureSecurity.note = t(
              "tools.ssl.general.messages.signatureInsecure"
            );
          } else if (insecureAlgorithms.includes(cert.signatureAlgorithmSN)) {
            signatureSecurity.cssStatus = "bg-warning";
            signatureSecurity.note = t(
              "tools.ssl.general.messages.signatureWeak"
            );
          } else if (secureAlgorithms.includes(cert.signatureAlgorithmSN)) {
            signatureSecurity.cssStatus = "bg-success";
          }
        }

        // return a new object with updated commonName
        return {
          ...cert,
          commonName: `${commonName} ${crtTyp}`,
          publicKeySecurity,
          signatureSecurity,
        };
      });
    });

    watch(updatedCertificates, () => {
      issuerValues.value = updatedCertificates.value.map((cert) => {
        let issuerCN = cert.issuerCN || "N/A";
        let issuerO = cert.issuerO || "N/A";
        let issuerC = cert.issuerC ? `, ${cert.issuerC}` : "";
        return `${issuerCN} (${issuerO}${issuerC})`;
      });
    });

    watch(processedCertificates, () => {
      issuerValues.value = processedCertificates.value.map((cert) => {
        let issuerCN = cert.issuerCN || "N/A";
        let issuerO = cert.issuerO || "N/A";
        let issuerC = cert.issuerC ? `, ${cert.issuerC}` : "";
        return `${issuerCN} (${issuerO}${issuerC})`;
      });
    });

    const decodeCertificates = debounce(async function () {
      errorValue.value = "";
      decodingError.value = "";

      let certs = certificates.value
        .split("-----END CERTIFICATE-----")
        .map((cert) => cert.trim() + "\n-----END CERTIFICATE-----")
        .filter((cert) => isValidCert(cert));

      certs = [...new Set(certs)];

      if (certs.length === 0) {
        errorValue.value = "No valid PEM-formatted certificates found.";
        return;
      }

      try {
        const baseUrl = process.env.VUE_APP_API_URL;
        const apiUrl = `${baseUrl}/ssl/crtDecoder`;

        const res = await fetch(apiUrl, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ certs: certs }),
          signal: abortController.signal,
        });

        if (!res.ok) {
          errorValue.value = `Server responded with status code ${res.status}`;
          return;
        }

        const data = await res.json();

        let decodingErrors: string[] = [];

        // Clear previous successful decodes
        rawCertificates.value = [];

        for (const cert of data.certificates) {
          if (cert.error) {
            decodingErrors.push(cert.error);
          } else {
            // Convert the fingerprints to uppercase
            cert.serial = cert.serial?.toLowerCase() ?? null;
            cert.subjectKeyIdentifier =
              cert.subjectKeyIdentifier?.toLowerCase() ?? null;
            cert.authorityKeyIdentifier =
              cert.authorityKeyIdentifier?.toLowerCase() ?? null;
            cert.sha1Fingerprint = cert.sha1Fingerprint?.toLowerCase() ?? null;
            cert.md5Fingerprint = cert.md5Fingerprint?.toLowerCase() ?? null;

            rawCertificates.value.push(cert);
          }
        }

        if (decodingErrors.length > 0) {
          decodingError.value =
            "We were unable do decode one or more certificates due to some conflicts with openssl. Please review your certificates!";
        }
      } catch (err) {
        if (err instanceof Error) {
          errorValue.value = err.message;
        } else {
          errorValue.value = "An error occurred";
        }
      }
    }, 1000);

    onUnmounted(() => {
      abortController.abort();
    });

    return {
      t,
      certificates,
      issuerValues,
      error: errorValue,
      decodingError,
      decodeCertificates,
      processedCertificates: updatedCertificates,
    };
  },
};
</script>
