import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2b6408df"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "row g-3" }
const _hoisted_2 = { class: "col-xl-6" }
const _hoisted_3 = { class: "col-xl-6" }
const _hoisted_4 = {
  key: 0,
  class: "alert alert-danger mt-3"
}
const _hoisted_5 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppHeader = _resolveComponent("AppHeader")!
  const _component_AppTextarea = _resolveComponent("AppTextarea")!
  const _component_AppContent = _resolveComponent("AppContent")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_AppHeader, {
      title: $setup.t('tools.ssl.certKeyMatcher.title'),
      description: $setup.t('tools.ssl.certKeyMatcher.description'),
      icon: ['fas', 'not-equal'],
      iconType: "fontawesome"
    }, null, 8, ["title", "description"]),
    _createVNode(_component_AppContent, null, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_AppTextarea, {
              value: $setup.certificate,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ($setup.certificate = $event)),
              spellcheck: "false",
              placeholder: "-----BEGIN CERTIFICATE-----\nMIID0DCCArigAwIBAgIBATANBgkqhkiG9w0BAQUFADB/MQswCQYDVQQGEwJGUjET\nvepIxc/1aHZrG+dPeEHt0MdFfOw13YdUc2FH6AqEdcEL4aV5PXq2eYR8hR4zKbc1\nfBtuqUsvA8NWSIyzQ16fyGve+ANf6vXvUizyvwDrPRv/kfvLNa3ZPnLMMxU98Mvh\nPXy3PkB8++6U4Y3vdk2Ni2WYYlIls8yqbM4327IKmkDc2TimS8u60CT47mKU7aDY\ncbTV5RDkrlaYwm5yqlTIglvCv7o=\n-----END CERTIFICATE-----"
            }, null, 8, ["value"])
          ]),
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_AppTextarea, {
              value: $setup.keyOrCsr,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ($setup.keyOrCsr = $event)),
              spellcheck: "false",
              placeholder: "-----BEGIN PRIVATE KEY-----\nMIIEowIBAAKCAQEAvpnaPKLIKdvx98KW68lz8pGaRRcYersNGqPjpifMVjjE8LuC\nCdLygS5RtgOLZib0IVErqWsP3EI1ACGuLts0vQ9GFLQGaN1SaMS40C9kvns1mlDu\nLhIhYpJ8UsCVt5snWo2N+M+6ANh5tpWdQnEK6zILh4tRbuzaiHgb\n-----END PRIVATE KEY-----\nor\n-----BEGIN CERTIFICATE REQUEST-----\nMIICvDCCAaQCAQAwdzELMAkGA1UEBhMCVVMxDTALBgNVBAgMBFV0YWgxDzANBgNV\n29XI1PpVUNCPQGn9p/eX6Qo7vpDaPybRtA2R7XLKjQaF9oXWeCUqy1hvJac9QFO2\n97Ob1alpHPoZ7mWiEuJwjBPii6a9M9G30nUo39lBi1w=\n-----END CERTIFICATE REQUEST-----\n"
            }, null, 8, ["value"])
          ])
        ])
      ]),
      _: 1
    }),
    ($setup.showResults && $setup.matchingError)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString($setup.matchingError), 1))
      : _createCommentVNode("", true),
    ($setup.showResults && $setup.matchResult)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createElementVNode("div", {
            class: _normalizeClass(["alert mt-3", $setup.matchResult.Matches ? 'alert-success' : 'alert-danger'])
          }, _toDisplayString($setup.matchResult.message), 3)
        ]))
      : _createCommentVNode("", true)
  ], 64))
}