import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "col-1 d-flex justify-content-center align-items-center" }
const _hoisted_2 = { class: "col-11" }
const _hoisted_3 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppHeader = _resolveComponent("AppHeader")!
  const _component_AppInput = _resolveComponent("AppInput")!
  const _component_AppSelectInput = _resolveComponent("AppSelectInput")!
  const _component_AppButton = _resolveComponent("AppButton")!
  const _component_AppInputGroup = _resolveComponent("AppInputGroup")!
  const _component_AppContent = _resolveComponent("AppContent")!
  const _component_AppLoading = _resolveComponent("AppLoading")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_AppHeader, {
      title: _ctx.t('tools.network.ping.title'),
      description: _ctx.t('tools.network.ping.description'),
      icon: ['fas', 'project-diagram'],
      iconType: "fontawesome"
    }, null, 8, ["title", "description"]),
    _createVNode(_component_AppContent, null, {
      default: _withCtx(() => [
        _createVNode(_component_AppInputGroup, null, {
          default: _withCtx(() => [
            _createVNode(_component_AppInput, {
              type: "text",
              class: "form-control-lg",
              placeholder: _ctx.t('tools.network.ping.hostPlaceholder'),
              modelValue: _ctx.sanitizedTarget,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.sanitizedTarget) = $event)),
              onInput: _ctx.onInputChange
            }, null, 8, ["placeholder", "modelValue", "onInput"]),
            _createVNode(_component_AppSelectInput, {
              style: {"width":"100px !important","max-width":"100px !important"},
              id: "ping-count",
              modelValue: _ctx.pingCount.toString(),
              options: _ctx.pingCountOptions,
              "onUpdate:modelValue": _ctx.onPingCountChange
            }, null, 8, ["modelValue", "options", "onUpdate:modelValue"]),
            _createVNode(_component_AppSelectInput, {
              style: {"width":"120px !important","max-width":"120px !important"},
              id: "ping-version",
              modelValue: _ctx.pingVersion,
              options: _ctx.pingVersionOptions,
              "onUpdate:modelValue": _ctx.onPingVersionChange
            }, null, 8, ["modelValue", "options", "onUpdate:modelValue"]),
            _createVNode(_component_AppButton, {
              btnClass: "btn btn-secondary",
              icon: ['fas', 'rotate'],
              title: "Reload",
              onClick: _ctx.onReload,
              disabled: !_ctx.sanitizedTarget || _ctx.invalidInput
            }, null, 8, ["onClick", "disabled"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    (
      _ctx.pingResult.length > 0 && _ctx.reachable && !_ctx.invalidInput && !_ctx.unreachableHost
    )
      ? (_openBlock(), _createBlock(_component_AppContent, { key: 0 }, {
          default: _withCtx(() => [
            _createElementVNode("pre", null, _toDisplayString(_ctx.formattedPingResult), 1)
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    (_ctx.loading)
      ? (_openBlock(), _createBlock(_component_AppLoading, { key: 1 }))
      : _createCommentVNode("", true),
    (_ctx.getAlertMessage)
      ? (_openBlock(), _createElementBlock("div", {
          key: 2,
          class: _normalizeClass(["mt-2 row alert d-flex align-items-center", _ctx.alertClass])
        }, [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_font_awesome_icon, {
              class: "pe-1 fa-2xl",
              icon: ['fas', 'triangle-exclamation']
            })
          ]),
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("span", { innerHTML: _ctx.getAlertMessage }, null, 8, _hoisted_3)
          ])
        ], 2))
      : _createCommentVNode("", true)
  ], 64))
}