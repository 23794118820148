<template>
  <input
    :type="type"
    :id="id"
    :class="inputClass"
    :placeholder="placeholder"
    :value="modelValue"
    :disabled="disabled"
    :readonly="readonly"
    :maxlength="maxlength"
    @input="updateValue"
  />
</template>

<script lang="ts">
// Importing modules
import { defineComponent } from "vue";

export default defineComponent({
  name: "AppInput",
  props: {
    type: {
      type: String,
      default: "text",
    },
    id: {
      type: String,
      required: false,
    },
    modelValue: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    inputClass: {
      type: String,
      default: "form-control",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    maxlength: {
      type: Number,
      default: null,
    },
  },
  emits: ["update:modelValue"],
  methods: {
    updateValue(e: Event) {
      //console.log((e.target as HTMLInputElement).value);
      this.$emit("update:modelValue", (e.target as HTMLInputElement).value);
    },
  },
});
</script>
