<template>
  <div class="card mt-2">
    <div class="card-body">
      <slot></slot>
    </div>
  </div>
</template>

<script lang="ts">
// Importing modules
import { defineComponent } from "vue";

export default defineComponent({
  name: "AppContent",
});
</script>
