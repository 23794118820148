export function isValidCert(value: string) {
  const certRegex =
    /^-----BEGIN (RSA )?CERTIFICATE-----\s*[A-Za-z0-9+/=\s]+\s*-----END (RSA )?CERTIFICATE-----\s*$/;
  return certRegex.test(value.trim());
}

export function isValidKey(value: string) {
  const keyRegex =
    /^-----BEGIN (RSA )?(PRIVATE KEY|RSA PRIVATE KEY)-----\s*[A-Za-z0-9+/=\s]+\s*-----END (RSA )?(PRIVATE KEY|RSA PRIVATE KEY)-----\s*$/;
  return keyRegex.test(value.trim());
}

export function isValidCsr(value: string) {
  const csrRegex =
    /^-----BEGIN CERTIFICATE REQUEST-----\s*[A-Za-z0-9+/=\s]+\s*-----END CERTIFICATE REQUEST-----\s*$/;
  return csrRegex.test(value.trim());
}
