import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["type", "id", "placeholder", "value", "disabled", "readonly", "maxlength"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("input", {
    type: _ctx.type,
    id: _ctx.id,
    class: _normalizeClass(_ctx.inputClass),
    placeholder: _ctx.placeholder,
    value: _ctx.modelValue,
    disabled: _ctx.disabled,
    readonly: _ctx.readonly,
    maxlength: _ctx.maxlength,
    onInput: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.updateValue && _ctx.updateValue(...args)))
  }, null, 42, _hoisted_1))
}