<template>
  <div class="container">
    <slot></slot>
  </div>
</template>

<script lang="ts">
// Importing modules
import { defineComponent } from "vue";

export default defineComponent({
  name: "AppContainer",
});
</script>
