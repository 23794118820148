// Importing modules
import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";

// Importing Main components
import Home from "../views/Home.vue";

// Importing SSL tools components
import CRTDecoder from "../views/ssl/CRTDecoder.vue";
import CSRDecoder from "../views/ssl/CSRDecoder.vue";
import CRTKEYMatcher from "../views/ssl/CertKeyMatcher.vue";
import CertLookup from "../views/ssl/CertLookup.vue";

// Importing Network tools components
import Ping from "../views/network/Ping.vue";
import Traceroute from "../views/network/Traceroute.vue";

// Importing Unix tools components
import ACLGenerator from "../views/unix/ACLGenerator.vue";
import CHMODGenerator from "../views/unix/CHMODGenerator.vue";

// Importing Misc tools components
import PasswordGenerator from "../views/misc/PasswordGenerator.vue";
import UUIDGenerator from "../views/misc/UUIDGenerator.vue";

// Defining routes
const routes: RouteRecordRaw[] = [
  // Main
  { path: "/", component: Home, meta: { titleKey: "home.title" } },
  // SSL tools
  {
    path: "/ssl/crtdecoder",
    component: CRTDecoder,
    meta: { titleKey: "tools.ssl.crtDecoder.title" },
  },
  {
    path: "/ssl/csrdecoder",
    component: CSRDecoder,
    meta: { titleKey: "tools.ssl.csrDecoder.title" },
  },
  {
    path: "/ssl/certkeymatcher",
    component: CRTKEYMatcher,
    meta: { titleKey: "tools.ssl.csrDecoder.title" },
  },
  {
    path: "/ssl/lookup",
    component: CertLookup,
    meta: { titleKey: "tools.ssl.certLookup.title" },
  },
  // Network tools
  {
    path: "/network/ping",
    component: Ping,
    meta: { titleKey: "tools.network.ping.title" },
  },
  {
    path: "/network/traceroute",
    component: Traceroute,
    meta: { titleKey: "tools.network.traceroute.title" },
  },
  // Unix tools
  {
    path: "/unix/acl-generator",
    component: ACLGenerator,
    meta: { titleKey: "tools.unix.aclGenerator.title" },
  },
  {
    path: "/unix/chmod-generator",
    component: CHMODGenerator,
    meta: { titleKey: "tools.unix.chmodGenerator.title" },
  },
  // Misc tools
  {
    path: "/misc/password-generator",
    component: PasswordGenerator,
    meta: { titleKey: "tools.misc.passwordGenerator.title" },
  },
  {
    path: "/misc/uuid-generator",
    component: UUIDGenerator,
    meta: { titleKey: "tools.misc.uuidGenerator.title" },
  },
];

// Creating router
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

// Exporting router
export default router;
