export function calculateRemainingDays(
  validToDate: string,
  t: (key: string) => string
) {
  const currentDate = new Date();
  const endDate = new Date(validToDate);

  // Check if validToDate is a valid date
  if (isNaN(endDate.getTime())) {
    // Return some default or error state
    return {
      remainingDays: NaN,
      cssStatus: "bg-danger",
      note: "",
    };
  }

  // The number of milliseconds in one day
  const oneDay = 24 * 60 * 60 * 1000;
  // Calculate the difference in milliseconds
  const diffMilliseconds = endDate.getTime() - currentDate.getTime();
  // Convert back to days and return
  const diffDays = Math.round(diffMilliseconds / oneDay);

  let cssStatus = "";
  let note = "";

  if (diffDays > 30) {
    cssStatus = "bg-success";
  } else if (diffDays <= 30 && diffDays > 0) {
    cssStatus = "bg-warning";
    note = t("tools.ssl.general.notes.expiring_soon");
  } else {
    cssStatus = "bg-danger";
    note = t("tools.ssl.general.expired");
  }

  return { remainingDays: diffDays, cssStatus: cssStatus, note: note };
}
