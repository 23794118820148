import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "table" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("table", _hoisted_1, [
    _createElementVNode("tbody", null, [
      _renderSlot(_ctx.$slots, "default")
    ])
  ]))
}