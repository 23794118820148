<template>
  <!-- Header -->
  <AppHeader
    :title="t('tools.misc.passwordGenerator.title')"
    :description="t('tools.misc.passwordGenerator.description')"
    :icon="['fab', 'linux']"
    iconType="fontawesome"
  />

  <!-- Single password output -->
  <AppAccordion>
    <AppAccordionItem
      alwaysOpen
      title="Password"
      :icon="['fas', 'key']"
      collapseId="singlePasswordsPanel-collapse"
      headerId="singlePasswordsPanel-header"
    >
      <AppInputGroup>
        <AppInput
          class="form-control-lg singlePassword font-monospace"
          :modelValue="singlePassword ? singlePassword.password : ''"
          @update:modelValue="
            (value) => {
              if (singlePassword) {
                singlePassword.password = value;
              }
            }
          "
          @click="selectValue"
          :readonly="true"
        />
        <AppButton btnClass="btn btn-password-strength" :disabled="true">
          <strong>
            {{ singlePassword ? singlePassword.strength : "" }} bits
            {{
              singlePassword ? "(" + singlePassword.strengthText + ")" : ""
            }} </strong
          ><br />
          <span>
            {{
              t("tools.misc.passwordGenerator.strength.messages.hackingTime")
            }}:
            {{ singlePassword?.timeToHack }}
          </span>
        </AppButton>
        <AppButton
          btnClass="btn btn-secondary"
          :icon="['fas', 'rotate']"
          title="Reload"
          @click="generatePasswords"
        />
        <AppButton
          btnClass="btn btn-secondary"
          :icon="['fas', 'copy']"
          title="Copy"
          @click="copyToClipboard"
        />
      </AppInputGroup>
    </AppAccordionItem>

    <!-- More passwords (mass export) -->
    <AppAccordionItem
      :title="t('tools.misc.passwordGenerator.more.title')"
      :icon="['fas', 'list-ol']"
      collapseId="morePasswordsPanel-collapse"
      headerId="morePasswordsPanel-header"
      isNew
    >
      <AppTextarea
        :readonly="true"
        class="form-control font-monospace"
        v-model="passwordList"
      />
    </AppAccordionItem>

    <!-- Password settings -->
    <AppAccordionItem
      :title="t('tools.misc.passwordGenerator.settings.title')"
      :icon="['fas', 'gear']"
      collapseId="settingsPanel-collapse"
      headerId="settingsPanel-header"
    >
      <div class="row pt-2">
        <div class="col-sm-12">
          <AppRangeInput
            label="Password Length"
            id="password-length"
            type="range"
            v-model="passwordLength"
            min="4"
            max="120"
            @input="generatePasswords"
          />
          <AppRangeInput
            label="Number of Passwords"
            id="amount-of-passwords"
            type="range"
            v-model="amountOfPasswords"
            min="5"
            max="50"
            step="5"
            @input="generatePasswords"
          />
        </div>
      </div>
      <hr class="mt-1 mb-4" />
      <div class="row">
        <div class="col-sm-5">
          <AppCheckboxInput
            id="upper-case-chars"
            :label="t('tools.misc.passwordGenerator.settings.upperCaseChars')"
            :disabled="options.realWords"
            v-model="options.upperCase"
            @input="generatePasswords"
            class="mb-2"
          />
          <AppCheckboxInput
            id="lower-case-chars"
            :label="t('tools.misc.passwordGenerator.settings.lowerCaseChars')"
            :disabled="options.realWords"
            v-model="options.lowerCase"
            @input="generatePasswords"
            class="mb-2"
          />
          <AppCheckboxInput
            id="numbers"
            :label="t('tools.misc.passwordGenerator.settings.numbers')"
            :disabled="options.realWords"
            v-model="options.numbers"
            @input="generatePasswords"
          />
        </div>
        <div class="col-sm-5">
          <AppCheckboxInput
            id="similiar-chars"
            :disabled="
              (!options.upperCase && !options.lowerCase) ||
              disableSpacesForShortPasswords ||
              options.realWords
            "
            v-model="options.similar"
            @input="generatePasswords"
            :label="t('tools.misc.passwordGenerator.settings.similarChars')"
            class="mb-2"
          />
          <AppCheckboxInput
            id="spaces"
            :disabled="
              (!options.upperCase && !options.lowerCase && !options.numbers) ||
              disableSpacesForShortPasswords ||
              options.realWords
            "
            v-model="options.spaces"
            @input="generatePasswords"
            :label="t('tools.misc.passwordGenerator.settings.spaces')"
            class="mb-2"
          />
          <AppCheckboxInput
            id="use-special-chars"
            :disabled="
              (!options.upperCase && !options.lowerCase && !options.numbers) ||
              disableSpacesForShortPasswords ||
              options.realWords
            "
            v-model="options.useSpecial"
            @input="generatePasswords"
            :label="t('tools.misc.passwordGenerator.settings.specialChars')"
            class="mb-2"
          />
          <div class="ms-4" v-show="options.useSpecial && !options.realWords">
            <AppSelectInput
              id="special-chars"
              v-model="options.special"
              :options="specialCharOptions"
            />
            <!-- Bug: Doesn't work with AppInput 
            <AppInput
              v-if="isCustom"
              class="mt-1"
              type="text"
              v-model="options.custom"
              @update:modelValue="updateCustomInput"
            />
            -->
            <input
              v-if="isCustom"
              type="text"
              v-model="options.custom"
              @input="updateCustomInput"
              class="form-control mt-1"
            />
          </div>
        </div>
      </div>
      <hr class="mt-3 mb-3" />
      <AppCheckboxInput
        id="real-words"
        v-model="options.realWords"
        @input="generatePasswords"
        :label="t('tools.misc.passwordGenerator.settings.realWords')"
        isBeta
      >
      </AppCheckboxInput>
    </AppAccordionItem>
  </AppAccordion>
  <div class="alert alert-danger mt-3" role="alert" v-if="errorMessage">
    {{ errorMessage }}
  </div>
</template>

<script lang="ts">
// Importing modules
import {
  defineComponent,
  ref,
  watch,
  computed,
  onMounted,
  watchEffect,
} from "vue";

// Importing components
import AppHeader from "@/components/card/AppHeader.vue";
import AppAccordion from "@/components/accordion/AppAccordion.vue";
import AppAccordionItem from "@/components/accordion/AppAccordionItem.vue";
import AppInputGroup from "@/components/input/AppInputGroup.vue";
import AppInput from "@/components/input/AppInput.vue";
import AppRangeInput from "@/components/input/AppRangeInput.vue";
import AppCheckboxInput from "@/components/input/AppCheckboxInput.vue";
import AppSelectInput from "@/components/input/AppSelectInput.vue";
import AppButton from "@/components/button/AppButton.vue";
import AppTextarea from "@/components/textarea/AppTextarea.vue";

// Importing helper
import { dictionary } from "../../utils/misc/PasswordGenerator/dictionary";

// Importing locales
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "PasswordGenerator",
  components: {
    AppHeader,
    AppAccordion,
    AppAccordionItem,
    AppInputGroup,
    AppInput,
    AppRangeInput,
    AppCheckboxInput,
    AppSelectInput,
    AppButton,
    AppTextarea,
  },
  data() {
    return {
      // Set of options for special characters used in password generation
      specialCharOptions: [
        {
          value: "basic",
          label: this.t(
            "tools.misc.passwordGenerator.settings.customChars.basic"
          ),
          description: "(#!$?)",
          disabled: false, // Add this line
        },
        {
          value: "medium",
          label: this.t(
            "tools.misc.passwordGenerator.settings.customChars.medium"
          ),
          description: "(.-_#!$?@)",
          disabled: false, // Add this line
        },
        {
          value: "advanced",
          label: this.t(
            "tools.misc.passwordGenerator.settings.customChars.advanced"
          ),
          description: "(!@#$%()-_=+:.&lt;&gt;?&)",
          disabled: false, // Add this line
        },
        {
          value: "custom",
          label: this.t(
            "tools.misc.passwordGenerator.settings.customChars.title"
          ),
          description: "",
          disabled: false, // Add this line
        },
      ],
    };
  },
  setup() {
    // Defining variables and refs used in the component
    const i18n = useI18n();
    const { t } = i18n;
    const passwordLength = ref(32);
    const amountOfPasswords = ref(10);
    const errorMessage = ref("");
    const disableSpacesForShortPasswords = computed(
      () => passwordLength.value < 8
    );
    const options = ref({
      upperCase: true,
      lowerCase: true,
      numbers: true,
      similar: false,
      useSpecial: true,
      special: "medium",
      custom: "!@#$%^*()-_=+[]{}|;:,.<>?&'",
      spaces: false,
      realWords: false,
    });

    // Represents a list of the allowed special characters for the password
    const allowedSpecialChars = "!@#$%^*()-_=+[]{}|;:,.<>?&'\"/\\";

    const pwStrengthVeryWeak = computed(() =>
      t("tools.misc.passwordGenerator.strength.veryWeak")
    );
    const pwStrengthWeak = computed(() =>
      t("tools.misc.passwordGenerator.strength.weak")
    );
    const pwStrengthMedium = computed(() =>
      t("tools.misc.passwordGenerator.strength.medium")
    );
    const pwStrengthStrong = computed(() =>
      t("tools.misc.passwordGenerator.strength.strong")
    );
    const pwStrengthVeryStrong = computed(() =>
      t("tools.misc.passwordGenerator.strength.veryStrong")
    );
    const pwStrengthMax = computed(() =>
      t("tools.misc.passwordGenerator.strength.maximum")
    );

    const passwords = ref<
      Array<{
        password: string;
        strength: number;
        strengthText: string;
        timeToHack: string;
      }>
    >([]);

    const singlePassword = computed(() => {
      return passwords.value.length > 0 ? passwords.value[0] : null;
    });

    const multiplePasswords = computed(() => {
      return passwords.value.slice(1);
    });

    const passwordList = computed(() => {
      return multiplePasswords.value
        .map((password) => password.password)
        .join("\n");
    });

    const isCustom = computed(() => options.value.special === "custom");

    // Update custom input option when changed
    const updateCustomInput = () => {
      options.value.custom = removeDuplicates(
        allowOnlyValidChars(options.value.custom)
      );
      generatePasswords();
    };

    // Remove invalid characters from the custom input
    const allowOnlyValidChars = (input: string) => {
      return input
        .split("")
        .filter((char) => allowedSpecialChars.includes(char))
        .join("");
    };

    // Remove duplicate characters from the custom input
    const removeDuplicates = (input: string) => {
      return Array.from(new Set(input.split(""))).join("");
    };

    // Watch for changes in the custom option and filter out invalid characters
    watch(
      () => options.value.custom,
      (newValue) => {
        const filteredChars = newValue
          .split("")
          .filter((char) => allowedSpecialChars.includes(char))
          .join("");
        if (newValue !== filteredChars) {
          options.value.custom = filteredChars;
        }
      }
    );

    // Load settings from local storage
    const loadSettings = () => {
      const storedSettings = localStorage.getItem("PasswordGenerator_settings");

      if (storedSettings) {
        const parsedSettings = JSON.parse(storedSettings);
        passwordLength.value = parsedSettings.passwordLength || 32;
        amountOfPasswords.value = parsedSettings.amountOfPasswords || 10;
        options.value = {
          ...options.value,
          ...parsedSettings.options,
          custom: parsedSettings.options.custom || options.value.custom,
        };
      }
    };

    // Save current settings to local storage
    const saveSettings = () => {
      const settings = {
        passwordLength: passwordLength.value,
        amountOfPasswords: amountOfPasswords.value,
        options: {
          ...options.value,
          special: isCustom.value ? "custom" : options.value.special,
        },
      };

      localStorage.setItem(
        "PasswordGenerator_settings",
        JSON.stringify(settings)
      );
    };

    // Update the special option when changed
    const updateSpecial = (event: Event) => {
      options.value.special = (event.target as HTMLSelectElement).value;
      generatePasswords();
    };

    // Generate a set of passwords according to the selected options
    const generatePasswords = () => {
      if (
        !options.value.upperCase &&
        !options.value.lowerCase &&
        !options.value.numbers &&
        !options.value.realWords
      ) {
        if (!options.value.spaces) {
          errorMessage.value = t(
            "tools.misc.passwordGenerator.messages.noOptions"
          );
          return;
        } else {
          options.value.spaces = false;
          errorMessage.value = t(
            "tools.misc.passwordGenerator.messages.onlySpaces"
          );
          return;
        }
      }

      errorMessage.value = "";
      saveSettings();
      const generatedPasswords: Array<{
        password: string;
        strength: number;
        strengthText: string;
        timeToHack: string;
      }> = [];

      for (let i = -1; i < amountOfPasswords.value; i++) {
        const result = generatePassword(
          passwordLength.value,
          options.value.upperCase,
          options.value.lowerCase,
          options.value.numbers,
          options.value.similar,
          options.value.special,
          options.value.spaces,
          options.value.realWords
        );

        const strengthText =
          result.strength < 28
            ? pwStrengthVeryWeak.value
            : result.strength < 36
            ? pwStrengthWeak.value
            : result.strength < 60
            ? pwStrengthMedium.value
            : result.strength < 100
            ? pwStrengthStrong.value
            : result.strength < 160
            ? pwStrengthVeryStrong.value
            : pwStrengthMax.value;
        const timeToHack = calculateTimeToHack(result.strength);
        generatedPasswords.push({
          password: result.password,
          strength: Math.round(result.strength),
          strengthText,
          timeToHack,
        });
      }

      passwords.value = generatedPasswords;
    };

    // Calculate the strength of a password
    const calculatePasswordStrength = (password: string): number => {
      const charsetSize = {
        upper: 26,
        lower: 26,
        digits: 10,
        special: 33,
      };

      let possibleCharacters = 0;

      if (/[A-Z]/.test(password)) possibleCharacters += charsetSize.upper;
      if (/[a-z]/.test(password)) possibleCharacters += charsetSize.lower;
      if (/\d/.test(password)) possibleCharacters += charsetSize.digits;
      if (/[-!@#$%^&*()_+=[\]{}|;:,.<>?']/g.test(password))
        possibleCharacters += charsetSize.special;

      const strengthInBits = Math.log2(
        Math.pow(possibleCharacters, password.length)
      );
      return strengthInBits;
    };

    // Calculate the estimated time to hack the password
    const calculateTimeToHack = (strength: number): string => {
      const hackSpeed = 1e9; // 1 billion guesses per second
      const guesses = 2 ** strength;
      const secondsToHack = guesses / hackSpeed;

      const units: [string, number][] = [
        ["second", 1],
        ["minute", 60],
        ["hour", 3600],
        ["day", 86400],
        ["week", 604800],
        ["month", 2592000],
        ["quarter", 7776000],
        ["year", 31536000],
        ["decade", 315360000],
        ["century", 3153600000],
      ];

      const idx = units.findIndex((_, i) => secondsToHack < units[i + 1]?.[1]);

      if (idx === -1 || idx === units.length - 1) {
        return t(
          "tools.misc.passwordGenerator.strength.messages.notPredictable"
        );
      }

      const [name, duration] = units[idx];
      const roundedTime = Math.floor(secondsToHack / duration);
      const result = `${roundedTime} ${t(
        `tools.misc.passwordGenerator.strength.time_units.${name}`
      )}`;

      if (idx >= 8) {
        return `${result} ${t(
          "tools.misc.passwordGenerator.strength.messages.notHackable"
        )}`;
      }

      return result;
    };

    // Generate a single password according to the selected options
    const generatePassword = (
      length: number,
      upperCase: boolean,
      lowerCase: boolean,
      numbers: boolean,
      similar: boolean,
      special: string,
      spaces: boolean,
      realWords: boolean
    ): { password: string; strength: number } => {
      // If using real words from the dictionary, generate the password accordingly
      if (realWords) {
        let password = "";
        let remainingLength = length;

        while (remainingLength > 0) {
          const suitableWords = dictionary.filter(
            (word) => word.length <= remainingLength
          );

          if (suitableWords.length === 0) {
            break;
          }

          const word =
            suitableWords[Math.floor(Math.random() * suitableWords.length)];

          // Randomly change the case of characters in the word
          const mixedCaseWord = word
            .split("")
            .map((char) =>
              Math.random() < 0.5 ? char.toUpperCase() : char.toLowerCase()
            )
            .join("");

          password += mixedCaseWord;
          remainingLength -= word.length;

          if (remainingLength > 0) {
            password += " ";
            remainingLength--;
          }
        }

        const strength = calculatePasswordStrength(password);
        return { password, strength };
      }

      // The original password generation logic
      const upperCaseChars = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
      const lowerCaseChars = "abcdefghijklmnopqrstuvwxyz";
      const numberChars = "0123456789";
      const similarChars = "il1Io0OQqg";
      const specialChars: Record<string, string> = {
        basic: "#!$?",
        medium: ".-_#!$?@",
        advanced: "!@#$%()-_=+:.<>?&",
      };

      let password = "";
      let charset = "";

      // Fill charset with enough characters from the available options
      while (charset.length < length) {
        if (spaces && length >= 8) {
          const numberOfSpaces = Math.floor(Math.random() * 6) + 2;
          charset += " ".repeat(numberOfSpaces);
        }

        if (upperCase) {
          charset += upperCaseChars;
        }

        if (lowerCase) {
          charset += lowerCaseChars;
        }

        if (numbers) {
          charset += numberChars;
        }

        if (similar) {
          charset = charset
            .split("")
            .filter((char) => !similarChars.includes(char))
            .join("");
        }

        if (
          options.value.useSpecial &&
          (special === "basic" ||
            special === "medium" ||
            special === "advanced")
        ) {
          charset += specialChars[special];
        } else if (options.value.useSpecial && special === "custom") {
          charset += options.value.custom || "!@#$%^*()-_=+[]{}|;:,.<>?&'";
        }
      }

      const getNonSpaceChar = () => {
        let nonSpaceChar;
        do {
          nonSpaceChar = charset[Math.floor(Math.random() * charset.length)];
        } while (nonSpaceChar === " ");
        return nonSpaceChar;
      };

      while (password.length < length) {
        const char = charset[Math.floor(Math.random() * charset.length)];

        // Prevent consecutive spaces
        if (char === " " && password[password.length - 1] === " ") {
          continue;
        }

        password += char;
      }

      // Ensure no spaces in the first two and last two characters
      for (let i = 0; i < 2; i++) {
        if (password[i] === " ") {
          password =
            password.slice(0, i) + getNonSpaceChar() + password.slice(i + 1);
        }
        if (password[password.length - 1 - i] === " ") {
          password =
            password.slice(0, password.length - 1 - i) +
            getNonSpaceChar() +
            password.slice(password.length - i);
        }
      }

      const strength = calculatePasswordStrength(password);
      return { password, strength };
    };

    const selectValue = () => {
      const inputElement = document.querySelector(
        ".singlePassword"
      ) as HTMLInputElement;
      if (inputElement) {
        inputElement.select();
        navigator.clipboard.writeText(inputElement.value);
      }
    };

    // Copy the first generated password to the clipboard
    const copyToClipboard = () => {
      if (passwords.value.length > 0) {
        navigator.clipboard.writeText(passwords.value[0].password);
      }
    };

    // Watch for changes in password length and amount and regenerate passwords when they occur
    watch(passwordLength, () => {
      generatePasswords();
    });

    watch(passwordLength, (newValue) => {
      if (newValue < 8) {
        options.value.spaces = false;
      }
    });

    watch(amountOfPasswords, () => {
      generatePasswords();
    });

    // Watch for changes in options and regenerate passwords when they occur
    watch(
      options,
      () => {
        generatePasswords();
      },
      { deep: true }
    );

    // Generate passwords when the component is mounted
    onMounted(() => {
      watchEffect(() => {
        i18n.locale; // This line is needed just to establish a reactive dependency.
        generatePasswords();
      });
    });

    // Load settings and generate passwords when the component is set up
    loadSettings();
    generatePasswords();

    return {
      t,
      passwordLength,
      amountOfPasswords,
      options,
      generatePasswords,
      isCustom,
      updateSpecial,
      updateCustomInput,
      disableSpacesForShortPasswords,
      errorMessage,
      singlePassword,
      multiplePasswords,
      passwordList,
      selectValue,
      copyToClipboard,
    };
  },
});
</script>
