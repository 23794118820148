<template>
  <button
    :type="type"
    :class="btnClass"
    :title="title"
    :disabled="disabled"
    @click="clickHandler"
  >
    <font-awesome-icon class="ps-2 pe-2" v-if="icon" :icon="icon" />
    <slot></slot>
  </button>
</template>

<script lang="ts">
// Importing modules
import { defineComponent } from "vue";

// Importing FontAwesome icons
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

export default defineComponent({
  name: "AppButton",
  components: {
    FontAwesomeIcon,
  },
  props: {
    type: {
      type: String as () => "button" | "submit" | "reset",
      default: "button",
    },
    btnClass: {
      type: String,
      required: true,
    },
    icon: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    clickHandler(e: Event) {
      this.$emit("click", e);
    },
  },
});
</script>
