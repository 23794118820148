<template>
  <!-- Header -->
  <AppHeader
    :title="t('tools.unix.aclGenerator.title')"
    :description="t('tools.unix.aclGenerator.description')"
    :icon="['fab', 'linux']"
    iconType="fontawesome"
  />

  <!-- Output -->
  <AppContent>
    <AppInputGroup>
      <AppInput
        type="text"
        inputClass="form-control form-control-lg font-monospace acl-rule"
        v-model="setfacl"
        readonly
        @click="selectValue"
      />
      <AppButton
        btnClass="btn btn-secondary btn-secondary-submit btn-lg"
        @click="copyToClipboard"
        :icon="['fas', 'copy']"
      />
    </AppInputGroup>

    <div class="row mt-4">
      <div class="col-lg-5">
        <AppInputGroup class="mb-3">
          <span class="input-group-text pe-3">{{
            t("tools.unix.aclGenerator.name")
          }}</span>
          <AppInput
            :maxlength="32"
            class="form-control"
            placeholder="bob"
            v-model="ug"
          />
          <AppSelectInput
            id="ugType"
            v-model="radioButton"
            :options="[
              {
                value: 'u',
                label: t('tools.unix.aclGenerator.ugOptions.user'),
                description: '',
                disabled: false,
              },
              {
                value: 'g',
                label: t('tools.unix.aclGenerator.ugOptions.group'),
                description: '',
                disabled: false,
              },
            ]"
          />
          <AppButton
            :btnClass="'btn btn-secondary'"
            @click="resetUg"
            :icon="['fas', 'undo']"
          />
        </AppInputGroup>
        <AppInputGroup>
          <span class="input-group-text pe-4">{{
            t("tools.unix.aclGenerator.path")
          }}</span>
          <AppInput
            :maxlength="255"
            placeholder="/tmp/alice-dir"
            class="form-control"
            v-model="path"
          />
          <AppButton
            btnClass="btn btn-secondary"
            @click="resetPath"
            :icon="['fas', 'undo']"
          />
        </AppInputGroup>
      </div>

      <div class="col-lg-5 ps-5">
        <AppCheckboxInput
          :label="t('tools.unix.aclGenerator.modes.recursive')"
          id="recursive"
          v-model="recursive"
          :hint="t('tools.unix.aclGenerator.modes.hints.recursive')"
          class="mb-3"
        />

        <AppCheckboxInput
          :label="t('tools.unix.aclGenerator.modes.modify')"
          id="modify"
          v-model="modify"
          :hint="t('tools.unix.aclGenerator.modes.hints.modify')"
          class="mb-3"
        />

        <AppCheckboxInput
          :label="t('tools.unix.aclGenerator.modes.default')"
          id="defaultOption"
          v-model="defaultOption"
          :hint="t('tools.unix.aclGenerator.modes.hints.default')"
        />
      </div>

      <div class="col-lg-2 ps-5">
        <AppCheckboxInput
          id="read"
          :label="t('tools.unix.aclGenerator.permissions.read')"
          v-model="read"
          class="mb-2"
        />
        <AppCheckboxInput
          id="write"
          :label="t('tools.unix.aclGenerator.permissions.write')"
          v-model="write"
          class="mb-2"
        />
        <AppCheckboxInput
          id="execute"
          :label="t('tools.unix.aclGenerator.permissions.execute')"
          v-model="execute"
          class="mb-2"
        />
      </div>
    </div>
  </AppContent>
</template>

<script lang="ts">
// Importing modules
import {
  defineComponent,
  ref,
  watchEffect,
  onMounted,
  onBeforeUnmount,
} from "vue";

// Importing components
import AppHeader from "@/components/card/AppHeader.vue";
import AppContent from "@/components/card/AppContent.vue";
import AppInputGroup from "@/components/input/AppInputGroup.vue";
import AppInput from "@/components/input/AppInput.vue";
import AppCheckboxInput from "@/components/input/AppCheckboxInput.vue";
import AppSelectInput from "@/components/input/AppSelectInput.vue";
import AppButton from "@/components/button/AppButton.vue";

// Importing locales
import { useI18n } from "vue-i18n";

// Sanitizing username and group name
const sanitizeUsername = (username: string): string => {
  const sanitizedUsername = username
    .toLowerCase()
    .replace(/[^a-z0-9_.-]+/g, "")
    .replace(/^[^a-z_]+/, "")
    .slice(0, 32);
  return sanitizedUsername;
};

// Sanitizing Unix path
const sanitizeUnixPath = (path: string): string => {
  const sanitizedPath = path
    .replace(/[^\w-./]+/g, "")
    .replace(/^-/, "")
    .trim();
  return sanitizedPath;
};

export default defineComponent({
  name: "ACLGenerator",
  components: {
    AppHeader,
    AppContent,
    AppInputGroup,
    AppInput,
    AppCheckboxInput,
    AppSelectInput,
    AppButton,
  },
  setup() {
    const i18n = useI18n();
    const { t } = i18n;
    const storedSettings = JSON.parse(
      localStorage.getItem("ACLGenerator_settings") || "{}"
    );

    const radioButton = ref(storedSettings.radioButton || "u");
    const ug = ref(storedSettings.ug || "bob");
    const path = ref(storedSettings.path || "/tmp/alice-dir");
    const recursive = ref(storedSettings.recursive || true);
    const modify = ref(storedSettings.modify || true);
    const defaultOption = ref(storedSettings.defaultOption || true);
    const read = ref(storedSettings.read || true);
    const write = ref(storedSettings.write || true);
    const execute = ref(storedSettings.execute || true);

    const setfacl = ref("");

    const saveToLocalStorage = () => {
      const settings = {
        radioButton: radioButton.value,
        ug: sanitizeUsername(ug.value),
        path: sanitizeUnixPath(path.value),
        recursive: recursive.value,
        modify: modify.value,
        defaultOption: defaultOption.value,
        read: read.value,
        write: write.value,
        execute: execute.value,
      };
      localStorage.setItem("ACLGenerator_settings", JSON.stringify(settings));
    };

    onMounted(() => {
      window.addEventListener("beforeunload", saveToLocalStorage);
    });

    onBeforeUnmount(() => {
      window.removeEventListener("beforeunload", saveToLocalStorage);
      saveToLocalStorage();
    });

    const resetUg = () => {
      ug.value = "bob";
    };

    const resetPath = () => {
      path.value = "/tmp/alice-dir";
    };

    const selectValue = () => {
      const inputElement = document.querySelector(
        ".acl-rule"
      ) as HTMLInputElement;
      if (inputElement) {
        inputElement.select();
        navigator.clipboard.writeText(inputElement.value);
      }
    };

    const copyToClipboard = () => {
      if (navigator.clipboard) {
        navigator.clipboard.writeText(setfacl.value);
      } else {
        // Fallback for browsers that don't support Clipboard API
        const textarea = document.createElement("textarea");
        textarea.value = setfacl.value;
        document.body.appendChild(textarea);
        textarea.select();
        document.execCommand("copy");
        document.body.removeChild(textarea);
      }
    };

    watchEffect(() => {
      let cmd = "setfacl ";
      let permissions = "";

      if (read.value) {
        permissions += "r";
      } else {
        permissions += "-";
      }

      if (write.value) {
        permissions += "w";
      } else {
        permissions += "-";
      }

      if (execute.value) {
        permissions += "x";
      } else {
        permissions += "-";
      }

      if (recursive.value) {
        cmd += "-R ";
      }

      if (modify.value) {
        cmd += "-m ";
      }

      cmd +=
        radioButton.value +
        ":" +
        sanitizeUsername(ug.value) +
        ":" +
        permissions;

      if (defaultOption.value) {
        cmd +=
          ",d:" +
          radioButton.value +
          ":" +
          sanitizeUsername(ug.value) +
          ":" +
          permissions;
      }

      // Sanitize username and group name before adding it to the command
      ug.value = sanitizeUsername(ug.value);

      // Sanitize path before adding it to the command
      path.value = sanitizeUnixPath(path.value);

      cmd += " " + path.value;

      setfacl.value = cmd;
    });

    return {
      t,
      radioButton,
      ug,
      path,
      recursive,
      modify,
      defaultOption,
      read,
      write,
      execute,
      setfacl,
      resetUg,
      resetPath,
      selectValue,
      copyToClipboard,
    };
  },
});
</script>
