import { createApp, watch } from "vue";
import App from "./App.vue";
import router from "./router";
import i18n from "./locales";
import "@/assets/css/themes.css";
import "@/assets/css/index.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(fas);
library.add(fab);

const app = createApp(App);

app.use(router);
app.use(i18n);
app.component("font-awesome-icon", FontAwesomeIcon);
app.mount("#app");

const updateTitle = (titleKey: string) => {
  if (titleKey) {
    const pageTitle = i18n.global.t(titleKey);
    document.title =
      titleKey === "home.title"
        ? "TechGeeks.io - IT swiss knife"
        : `${pageTitle} - TechGeeks.io`;
  } else {
    document.title = "TechGeeks.io";
  }
};

router.afterEach((to) => {
  const titleKey = to.meta.titleKey as string;
  updateTitle(titleKey);
});

watch(
  () => i18n.global.locale,
  () => {
    const route = router.currentRoute.value;
    const titleKey = route.meta.titleKey as string;
    updateTitle(titleKey);
  }
);
