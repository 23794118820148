import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row mt-4" }
const _hoisted_2 = { class: "col-lg-5" }
const _hoisted_3 = { class: "input-group-text pe-3" }
const _hoisted_4 = { class: "input-group-text pe-4" }
const _hoisted_5 = { class: "col-lg-5 ps-5" }
const _hoisted_6 = { class: "col-lg-2 ps-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppHeader = _resolveComponent("AppHeader")!
  const _component_AppInput = _resolveComponent("AppInput")!
  const _component_AppButton = _resolveComponent("AppButton")!
  const _component_AppInputGroup = _resolveComponent("AppInputGroup")!
  const _component_AppSelectInput = _resolveComponent("AppSelectInput")!
  const _component_AppCheckboxInput = _resolveComponent("AppCheckboxInput")!
  const _component_AppContent = _resolveComponent("AppContent")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_AppHeader, {
      title: _ctx.t('tools.unix.aclGenerator.title'),
      description: _ctx.t('tools.unix.aclGenerator.description'),
      icon: ['fab', 'linux'],
      iconType: "fontawesome"
    }, null, 8, ["title", "description"]),
    _createVNode(_component_AppContent, null, {
      default: _withCtx(() => [
        _createVNode(_component_AppInputGroup, null, {
          default: _withCtx(() => [
            _createVNode(_component_AppInput, {
              type: "text",
              inputClass: "form-control form-control-lg font-monospace acl-rule",
              modelValue: _ctx.setfacl,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.setfacl) = $event)),
              readonly: "",
              onClick: _ctx.selectValue
            }, null, 8, ["modelValue", "onClick"]),
            _createVNode(_component_AppButton, {
              btnClass: "btn btn-secondary btn-secondary-submit btn-lg",
              onClick: _ctx.copyToClipboard,
              icon: ['fas', 'copy']
            }, null, 8, ["onClick"])
          ]),
          _: 1
        }),
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_AppInputGroup, { class: "mb-3" }, {
              default: _withCtx(() => [
                _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.t("tools.unix.aclGenerator.name")), 1),
                _createVNode(_component_AppInput, {
                  maxlength: 32,
                  class: "form-control",
                  placeholder: "bob",
                  modelValue: _ctx.ug,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.ug) = $event))
                }, null, 8, ["modelValue"]),
                _createVNode(_component_AppSelectInput, {
                  id: "ugType",
                  modelValue: _ctx.radioButton,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.radioButton) = $event)),
                  options: [
              {
                value: 'u',
                label: _ctx.t('tools.unix.aclGenerator.ugOptions.user'),
                description: '',
                disabled: false,
              },
              {
                value: 'g',
                label: _ctx.t('tools.unix.aclGenerator.ugOptions.group'),
                description: '',
                disabled: false,
              },
            ]
                }, null, 8, ["modelValue", "options"]),
                _createVNode(_component_AppButton, {
                  btnClass: 'btn btn-secondary',
                  onClick: _ctx.resetUg,
                  icon: ['fas', 'undo']
                }, null, 8, ["onClick"])
              ]),
              _: 1
            }),
            _createVNode(_component_AppInputGroup, null, {
              default: _withCtx(() => [
                _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.t("tools.unix.aclGenerator.path")), 1),
                _createVNode(_component_AppInput, {
                  maxlength: 255,
                  placeholder: "/tmp/alice-dir",
                  class: "form-control",
                  modelValue: _ctx.path,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.path) = $event))
                }, null, 8, ["modelValue"]),
                _createVNode(_component_AppButton, {
                  btnClass: "btn btn-secondary",
                  onClick: _ctx.resetPath,
                  icon: ['fas', 'undo']
                }, null, 8, ["onClick"])
              ]),
              _: 1
            })
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_AppCheckboxInput, {
              label: _ctx.t('tools.unix.aclGenerator.modes.recursive'),
              id: "recursive",
              modelValue: _ctx.recursive,
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.recursive) = $event)),
              hint: _ctx.t('tools.unix.aclGenerator.modes.hints.recursive'),
              class: "mb-3"
            }, null, 8, ["label", "modelValue", "hint"]),
            _createVNode(_component_AppCheckboxInput, {
              label: _ctx.t('tools.unix.aclGenerator.modes.modify'),
              id: "modify",
              modelValue: _ctx.modify,
              "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.modify) = $event)),
              hint: _ctx.t('tools.unix.aclGenerator.modes.hints.modify'),
              class: "mb-3"
            }, null, 8, ["label", "modelValue", "hint"]),
            _createVNode(_component_AppCheckboxInput, {
              label: _ctx.t('tools.unix.aclGenerator.modes.default'),
              id: "defaultOption",
              modelValue: _ctx.defaultOption,
              "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.defaultOption) = $event)),
              hint: _ctx.t('tools.unix.aclGenerator.modes.hints.default')
            }, null, 8, ["label", "modelValue", "hint"])
          ]),
          _createElementVNode("div", _hoisted_6, [
            _createVNode(_component_AppCheckboxInput, {
              id: "read",
              label: _ctx.t('tools.unix.aclGenerator.permissions.read'),
              modelValue: _ctx.read,
              "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.read) = $event)),
              class: "mb-2"
            }, null, 8, ["label", "modelValue"]),
            _createVNode(_component_AppCheckboxInput, {
              id: "write",
              label: _ctx.t('tools.unix.aclGenerator.permissions.write'),
              modelValue: _ctx.write,
              "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.write) = $event)),
              class: "mb-2"
            }, null, 8, ["label", "modelValue"]),
            _createVNode(_component_AppCheckboxInput, {
              id: "execute",
              label: _ctx.t('tools.unix.aclGenerator.permissions.execute'),
              modelValue: _ctx.execute,
              "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.execute) = $event)),
              class: "mb-2"
            }, null, 8, ["label", "modelValue"])
          ])
        ])
      ]),
      _: 1
    })
  ], 64))
}