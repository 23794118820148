<template>
  <div class="row mb-3 align-items-center">
    <div class="col-sm-2">
      <label :for="id" class="form-label">{{ label }}</label>
    </div>
    <div class="col-sm-10 d-flex align-items-center">
      <input
        :type="type"
        :id="id"
        :min="min"
        :max="max"
        :step="step"
        :value="modelValue"
        @input="updateValue"
        class="form-range"
      />
      <span class="badge bg-secondary badge-numbers ms-3">{{
        modelValue
      }}</span>
    </div>
  </div>
</template>

<script lang="ts">
// Importing modules
import { defineComponent } from "vue";

export default defineComponent({
  name: "AppRangeInput",
  props: {
    label: {
      type: String,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      default: "range",
    },
    modelValue: {
      type: [Number, String],
      required: true,
    },
    min: {
      type: [Number, String],
      default: 0,
    },
    max: {
      type: [Number, String],
      default: 100,
    },
    step: {
      type: [Number, String],
      default: 1,
    },
  },
  methods: {
    updateValue(e: Event) {
      const target = e.target as HTMLInputElement;
      this.$emit("update:modelValue", target.valueAsNumber);
      this.$emit("input", e);
    },
  },
});
</script>
