<template>
  <nav class="navbar navbar-expand-lg">
    <div class="container-fluid">
      <router-link class="navbar-brand" to="/">
        <img
          :src="require('@/assets/images/logo.png')"
          alt="Logo"
          height="60"
        />
      </router-link>
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarNav"
        aria-controls="navbarNav"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarNav">
        <ul class="navbar-nav">
          <li class="nav-item">
            <router-link class="nav-link" to="/" exact>{{
              $t("home.title")
            }}</router-link>
          </li>
          <li class="nav-item dropdown">
            <a
              class="nav-link dropdown-toggle dropdown-toggle-no-caret"
              href="#"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              SSL tools
            </a>
            <ul class="dropdown-menu">
              <router-link class="dropdown-item" to="/ssl/crtdecoder"
                ><font-awesome-icon
                  :icon="['fas', 'angle-right']"
                  class="me-2 fa-2xs"
                />
                {{ $t("tools.ssl.crtDecoder.title") }}</router-link
              >
              <router-link class="dropdown-item" to="/ssl/csrdecoder"
                ><font-awesome-icon
                  :icon="['fas', 'angle-right']"
                  class="me-2 fa-2xs"
                />
                {{ $t("tools.ssl.csrDecoder.title") }}</router-link
              >
              <router-link class="dropdown-item" to="/ssl/certkeymatcher"
                ><font-awesome-icon
                  :icon="['fas', 'angle-right']"
                  class="me-2 fa-2xs"
                />
                {{ $t("tools.ssl.certKeyMatcher.title") }}</router-link
              >
              <router-link class="dropdown-item" to="/ssl/lookup"
                ><font-awesome-icon
                  :icon="['fas', 'angle-right']"
                  class="me-2 fa-2xs"
                />
                {{ $t("tools.ssl.certLookup.title") }}</router-link
              >
            </ul>
          </li>
          <li class="nav-item dropdown">
            <a
              class="nav-link dropdown-toggle dropdown-toggle-no-caret"
              href="#"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              Network tools
            </a>
            <ul class="dropdown-menu">
              <router-link class="dropdown-item" to="/network/ping"
                ><font-awesome-icon
                  :icon="['fas', 'angle-right']"
                  class="me-2 fa-2xs"
                />
                {{ $t("tools.network.ping.title") }}</router-link
              >
            </ul>
          </li>
          <li class="nav-item dropdown">
            <a
              class="nav-link dropdown-toggle dropdown-toggle-no-caret"
              href="#"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              Unix tools
            </a>
            <ul class="dropdown-menu">
              <router-link class="dropdown-item" to="/unix/acl-generator"
                ><font-awesome-icon
                  :icon="['fas', 'angle-right']"
                  class="me-2 fa-2xs"
                />{{ $t("tools.unix.aclGenerator.title") }}</router-link
              >
              <router-link class="dropdown-item" to="/unix/chmod-generator"
                ><font-awesome-icon
                  :icon="['fas', 'angle-right']"
                  class="me-2 fa-2xs"
                />{{ $t("tools.unix.chmodGenerator.title") }}</router-link
              >
            </ul>
          </li>
          <li class="nav-item dropdown">
            <a
              class="nav-link dropdown-toggle dropdown-toggle-no-caret"
              href="#"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              Misc tools
            </a>
            <ul class="dropdown-menu">
              <router-link class="dropdown-item" to="/misc/password-generator"
                ><font-awesome-icon
                  :icon="['fas', 'angle-right']"
                  class="me-2 fa-2xs"
                />{{ $t("tools.misc.passwordGenerator.title") }}</router-link
              >
              <router-link class="dropdown-item" to="/misc/uuid-generator"
                ><font-awesome-icon
                  :icon="['fas', 'angle-right']"
                  class="me-2 fa-2xs"
                />{{ $t("tools.misc.uuidGenerator.title") }}</router-link
              >
            </ul>
          </li>
        </ul>
      </div>
      <ul class="navbar-nav ml-auto">
        <li class="nav-item">
          <a href="#" class="nav-link" @click="toggleTheme">
            <font-awesome-icon :icon="['fas', 'circle-half-stroke']" />
          </a>
        </li>
        <li class="nav-item dropdown">
          <a
            class="nav-link dropdown-toggle dropdown-toggle-no-caret"
            href="#"
            id="languageDropdown"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <img
              :src="getFlagPath(`circle/${selectedLanguageKey}.png`)"
              :alt="languages[selectedLanguageKey].language"
              class="language-flag"
              width="28"
            />
          </a>
          <ul
            class="dropdown-menu dropdown-menu-end"
            aria-labelledby="languageDropdown"
          >
            <li v-for="(value, key) in languages" :key="key">
              <a class="dropdown-item" href="#" @click="switchLanguage(key)">
                <img
                  :src="getFlagPath(`small/${key}.png`)"
                  :alt="value.language"
                  class="language-flag me-1"
                />
                {{ value.language }}
              </a>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </nav>
</template>

<script lang="ts">
import { defineComponent, ref, PropType } from "vue";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "AppNavbar",
  props: {
    toggleTheme: {
      type: Function as PropType<(event: MouseEvent) => void>,
      required: true,
    },
  },
  setup() {
    const i18n = useI18n();
    const { t, locale, availableLocales, messages } = i18n;
    const selectedLanguageKey = ref(locale.value);

    const languages: Record<string, { language: string; flag: string }> =
      availableLocales.reduce(
        (
          acc: Record<string, { language: string; flag: string }>,
          key: string
        ) => {
          const value = messages.value[key] as {
            general: { language: string; flag: string };
          };
          acc[key] = {
            language: value.general.language,
            flag: value.general.flag,
          };
          return acc;
        },
        {}
      );

    const switchLanguage = (langKey: string) => {
      i18n.locale.value = langKey;
      selectedLanguageKey.value = langKey;
      localStorage.setItem("language", langKey);
    };

    const getFlagPath = (relativePath: string) => {
      return require(`@/assets/images/flags/${relativePath}`);
    };

    return {
      t,
      languages,
      switchLanguage,
      locale: i18n.locale,
      selectedLanguageKey,
      getFlagPath,
    };
  },
});
</script>
