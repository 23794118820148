<template>
  <AppHeader
    :title="t('tools.misc.uuidGenerator.title')"
    :description="t('tools.misc.uuidGenerator.description')"
    :icon="['fab', 'linux']"
    iconType="fontawesome"
  />

  <AppAccordion>
    <AppAccordionItem
      alwaysOpen
      title="UUID"
      :icon="['fas', 'key']"
      collapseId="UUIDPanel-collapse"
      headerId="UUIDPanel-header"
    >
      <AppInputGroup>
        <AppInput
          class="form-control-lg font-monospace"
          :value="currentUUID"
          @click="selectValue"
          :readonly="true"
        />
        <AppSelectInput
          style="width: 270px !important; max-width: 270px !important"
          id="uuid-version"
          :modelValue="selectedVersion"
          :options="uuidVersionOptions"
          @update:modelValue="selectedVersion = $event"
        />
        <AppButton
          btnClass="btn btn-secondary"
          :icon="['fas', 'rotate']"
          title="Reload"
          :disabled="selectedVersion === 3 || selectedVersion === 5"
          @click="onReload"
        />
        <AppButton
          btnClass="btn btn-secondary"
          :icon="['fas', 'copy']"
          title="Copy"
          :disabled="currentUUID === 'xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx'"
          @click="copyToClipboard"
        />
      </AppInputGroup>
      <!-- Identifier Group, only visible when selected Version 3 and 5 -->
      <AppInputGroup
        class="mt-2"
        v-if="selectedVersion === 3 || selectedVersion === 5"
      >
        <AppSelectInput
          style="width: 270px !important; max-width: 270px !important"
          id="uuid-identifier"
          :modelValue="selectedIdentifier"
          :options="uuidIdentifierOptions"
          @update:modelValue="selectedIdentifier = $event"
        />
        <!-- Input customIdentifier only visible when selected custom -->
        <AppInput
          class="form-control-lg font-monospace"
          v-model="customIdentifier"
          v-if="selectedIdentifier === 'custom'"
          placeholder="00000000-0000-0000-0000-000000000000"
        />
        <AppInput
          class="form-control-lg font-monospace"
          v-model="identifierName"
          placeholder="Name (any value)"
        />
      </AppInputGroup>
    </AppAccordionItem>

    <!-- More UUIDs (mass export) -->
    <AppAccordionItem
      :title="t('tools.misc.uuidGenerator.more.title')"
      :icon="['fas', 'list-ol']"
      collapseId="moreUuidsPanel-collapse"
      headerId="moreUuidsPanel-header"
      isNew
      v-if="selectedVersion !== 3 && selectedVersion !== 5"
    >
      <AppTextarea
        :readonly="true"
        class="form-control font-monospace"
        v-model="uuidList"
      />
    </AppAccordionItem>
  </AppAccordion>
</template>

<script lang="ts">
// Importing modules
import { defineComponent, ref, onMounted, watch } from "vue";
import {
  v1 as uuidv1,
  v4 as uuidv4,
  v3 as uuidv3,
  v5 as uuidv5,
  validate,
} from "uuid";
import validator from "validator";

// Importing components
import AppHeader from "@/components/card/AppHeader.vue";
import AppAccordion from "@/components/accordion/AppAccordion.vue";
import AppAccordionItem from "@/components/accordion/AppAccordionItem.vue";
import AppInputGroup from "@/components/input/AppInputGroup.vue";
import AppInput from "@/components/input/AppInput.vue";
import AppSelectInput from "@/components/input/AppSelectInput.vue";
import AppTextarea from "@/components/textarea/AppTextarea.vue";
import AppButton from "@/components/button/AppButton.vue";

// Importing locales
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "uuidGenerator",
  components: {
    AppHeader,
    AppAccordion,
    AppAccordionItem,
    AppInputGroup,
    AppInput,
    AppSelectInput,
    AppTextarea,
    AppButton,
  },
  setup() {
    const i18n = useI18n();
    const { t } = i18n;

    // Create a ref to hold the current UUID and the list of UUIDs
    const currentUUID = ref("");
    const uuidList = ref("");
    const selectedVersion = ref(4);

    const selectedIdentifier = ref("dns");
    const customIdentifier = ref("");
    const identifierName = ref("");

    const uuidVersionOptions = [
      {
        value: 1,
        label: "Version 1 (MAC address)",
        description: "",
        disabled: false,
      },
      { value: 3, label: "Version 3 (MD5)", description: "", disabled: false },
      {
        value: 4,
        label: "Version 4 (Random)",
        description: "",
        disabled: false,
      },
      {
        value: 41,
        label: "Version 4.1 (Timestamp-first)",
        description: "",
        disabled: false,
      },
      {
        value: 5,
        label: "Version 5 (SHA-1)",
        description: "",
        disabled: false,
      },
    ];

    const uuidIdentifierOptions = [
      {
        value: "dns",
        label: "ns:DNS (Domain names)",
        description: "",
        disabled: false,
      },
      {
        value: "url",
        label: "ns:URL (URLs)",
        description: "",
        disabled: false,
      },
      {
        value: "oid",
        label: "ns:OID (ISO OID)",
        description: "",
        disabled: false,
      },
      {
        value: "x500",
        label: "ns:X500 (X.500 DN)",
        description: "",
        disabled: false,
      },
      { value: "custom", label: "ns:custom", description: "", disabled: false },
    ];

    const predefinedNamespaces: { [key: string]: string } = {
      dns: "6ba7b810-9dad-11d1-80b4-00c04fd430c8",
      url: "6ba7b811-9dad-11d1-80b4-00c04fd430c8",
      oid: "6ba7b812-9dad-11d1-80b4-00c04fd430c8",
      x500: "6ba7b814-9dad-11d1-80b4-00c04fd430c8",
    };

    function onReload() {
      currentUUID.value = generateUUID();
      if (selectedVersion.value !== 3 && selectedVersion.value !== 5) {
        uuidList.value = "";
        for (let i = 0; i < 10; i++) {
          uuidList.value += generateUUID() + "\n";
        }
      }
    }

    function generateCombUUID() {
      let timestamp = Date.now().toString(16);
      let uuid = uuidv4();
      return timestamp + uuid.substring(timestamp.length);
    }

    function generateUUID() {
      const namespaceUUID =
        selectedIdentifier.value === "custom"
          ? customIdentifier.value
          : predefinedNamespaces[selectedIdentifier.value];

      if (
        (selectedVersion.value === 3 || selectedVersion.value === 5) &&
        (namespaceUUID === undefined ||
          identifierName.value === "" ||
          !validate(namespaceUUID) ||
          (selectedIdentifier.value === "custom" &&
            !validator.isUUID(customIdentifier.value)))
      ) {
        // Don't generate UUID if namespace or name is missing or namespace is invalid for v3 and v5
        return "xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx";
      }

      switch (selectedVersion.value) {
        case 1:
          return uuidv1();
        case 3:
          return uuidv3(identifierName.value, namespaceUUID);
        case 5:
          return uuidv5(identifierName.value, namespaceUUID);
        case 41:
          return generateCombUUID();
        case 4:
        default:
          return uuidv4();
      }
    }

    function copyToClipboard() {
      if (navigator.clipboard) {
        navigator.clipboard.writeText(currentUUID.value);
      } else {
        // Fallback for browsers that don't support Clipboard API
        const textarea = document.createElement("textarea");
        textarea.value = currentUUID.value;
        document.body.appendChild(textarea);
        textarea.select();
        document.execCommand("copy");
        document.body.removeChild(textarea);
      }
    }

    function selectValue(event: MouseEvent) {
      if (currentUUID.value !== "xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx") {
        const target = event.target as HTMLInputElement;
        target.select();
        copyToClipboard();
      }
    }

    onMounted(onReload);
    watch(
      [selectedVersion, selectedIdentifier, customIdentifier, identifierName],
      () => {
        onReload();
      }
    );

    return {
      t,
      currentUUID,
      onReload,
      uuidList,
      copyToClipboard,
      selectValue,
      selectedVersion,
      selectedIdentifier,
      customIdentifier,
      identifierName,
      uuidVersionOptions,
      uuidIdentifierOptions,
    };
  },
});
</script>
