<template>
  <div class="accordion-item">
    <h2 class="accordion-header" :id="headerId">
      <button
        class="accordion-button"
        :class="{ collapsed: !alwaysOpen, 'visually-hidden': alwaysOpen }"
        type="button"
        :data-bs-target="`#${collapseId}`"
        data-bs-toggle="collapse"
        :aria-expanded="alwaysOpen ? 'true' : 'false'"
        :aria-controls="collapseId"
      >
        <font-awesome-icon class="pe-2" :icon="icon" />
        {{ title }}
        <span class="badge rounded-pill text-bg-success ms-2" v-if="isNew"
          >NEW</span
        >
      </button>
    </h2>
    <div
      :id="collapseId"
      class="accordion-collapse collapse"
      :class="{ show: alwaysOpen }"
      :aria-labelledby="headerId"
    >
      <div class="accordion-body">
        <slot />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
export default {
  name: "AppAccordionItem",
  props: {
    title: {
      type: String,
      required: true,
    },
    icon: {
      type: Array,
      required: true,
    },
    collapseId: {
      type: String,
      required: true,
    },
    headerId: {
      type: String,
      required: true,
    },
    isNew: {
      type: Boolean,
      default: false,
    },
    alwaysOpen: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
