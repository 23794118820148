<template>
  <div class="accordion mt-3">
    <slot />
  </div>
</template>

<script lang="ts">
export default {
  name: "AppAccordion",
};
</script>
