import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row mb-3 align-items-center" }
const _hoisted_2 = { class: "col-sm-2" }
const _hoisted_3 = ["for"]
const _hoisted_4 = { class: "col-sm-10 d-flex align-items-center" }
const _hoisted_5 = ["type", "id", "min", "max", "step", "value"]
const _hoisted_6 = { class: "badge bg-secondary badge-numbers ms-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("label", {
        for: _ctx.id,
        class: "form-label"
      }, _toDisplayString(_ctx.label), 9, _hoisted_3)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("input", {
        type: _ctx.type,
        id: _ctx.id,
        min: _ctx.min,
        max: _ctx.max,
        step: _ctx.step,
        value: _ctx.modelValue,
        onInput: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.updateValue && _ctx.updateValue(...args))),
        class: "form-range"
      }, null, 40, _hoisted_5),
      _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.modelValue), 1)
    ])
  ]))
}