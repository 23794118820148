<template>
  <div class="input-group">
    <slot></slot>
  </div>
</template>

<script lang="ts">
// Importing modules
import { defineComponent } from "vue";

export default defineComponent({
  name: "AppInputGroup",
});
</script>
