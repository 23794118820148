<template>
  <div id="app">
    <AppNavbar :toggle-theme="toggleTheme" />
    <AppContainer>
      <router-view />
    </AppContainer>
    <AppFooter :toggle-theme="toggleTheme" />
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref, provide } from "vue";
import AppNavbar from "./components/common/AppNavbar.vue";
import AppFooter from "./components/common/AppFooter.vue";
import AppContainer from "./components/container/AppContainer.vue";

export default defineComponent({
  components: {
    AppNavbar,
    AppFooter,
    AppContainer,
  },
  setup() {
    const darkTheme = ref(false);

    const updateTheme = () => {
      if (darkTheme.value) {
        document.documentElement.classList.add("dark");
      } else {
        document.documentElement.classList.remove("dark");
      }
      localStorage.setItem("dark_theme", JSON.stringify(darkTheme.value));
    };

    const toggleTheme = () => {
      darkTheme.value = !darkTheme.value;
      updateTheme();
    };

    const setDefaultTheme = () => {
      const savedTheme = localStorage.getItem("dark_theme");
      if (savedTheme !== null) {
        darkTheme.value = JSON.parse(savedTheme);
      } else {
        const prefersDarkScheme = window.matchMedia(
          "(prefers-color-scheme: dark)"
        );
        darkTheme.value = prefersDarkScheme.matches;
      }
      updateTheme();
    };

    onMounted(setDefaultTheme);

    // Provide darkTheme to child components
    provide("darkTheme", darkTheme);

    return { toggleTheme };
  },
});
</script>
