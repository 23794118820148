import { createI18n } from "vue-i18n";
import en from "./en.json";
import de from "./de.json";

const messages = {
  en,
  de,
};

const getSavedLanguage = (): string => {
  const savedLanguage = localStorage.getItem("language");
  if (savedLanguage) {
    return savedLanguage;
  }
  const browserLanguage = navigator.language.slice(0, 2);
  return Object.keys(messages).includes(browserLanguage)
    ? browserLanguage
    : "en";
};

const i18n = createI18n({
  locale: getSavedLanguage(),
  messages,
  warnHtmlMessage: false,
  legacy: false,
});

export default i18n;
