<template>
  <textarea
    :value="modelValue"
    :placeholder="placeholder"
    :rows="rows"
    :disabled="disabled"
    :readonly="readonly"
    :maxlength="maxlength"
    @input="updateValue"
    class="form-control"
  ></textarea>
</template>

<script lang="ts">
// Importing modules
import { defineComponent } from "vue";

export default defineComponent({
  name: "AppTextarea",
  props: {
    modelValue: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    rows: {
      type: Number,
      default: 10,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    maxlength: {
      type: Number,
      default: null,
    },
  },
  methods: {
    updateValue(e: Event) {
      const target = e.target as HTMLTextAreaElement;
      this.$emit("update:modelValue", target.value);
      this.$emit("input", target.value); // pass new value to input event
    },
  },
});
</script>
