<template>
  <select
    :id="id"
    :value="modelValue"
    @change="updateValue"
    class="form-select"
  >
    <option
      v-for="option in options"
      :key="option.value"
      :value="option.value"
      :disabled="option.disabled"
    >
      {{ option.label }} {{ option.description }}
    </option>
  </select>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "AppSelectInput",
  props: {
    id: {
      type: String,
      required: true,
    },
    modelValue: {
      type: [String, Number],
      required: true,
    },
    options: {
      type: Array as () => Array<{
        value: string | number;
        label: string;
        description: string;
        disabled: boolean;
      }>,
      required: true,
    },
  },
  methods: {
    updateValue(event: Event) {
      const target = event.target as HTMLSelectElement;
      const value = isNaN(Number(target.value))
        ? target.value
        : Number(target.value);
      this.$emit("update:modelValue", value);
    },
  },
});
</script>
