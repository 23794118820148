// Classification of signature algorithms
export const secureAlgorithms: string[] = [
  "RSA-SHA224",
  "RSA-SHA256",
  "RSA-SHA384",
  "RSA-SHA512",
  "RSA-SHA3-224",
  "RSA-SHA3-256",
  "RSA-SHA3-384",
  "RSA-SHA3-512",
  "RSA-SHA256-MGF1",
  "RSA-SHA384-MGF1",
  "RSA-SHA512-MGF1",
  "RSA-SHA3-224-MGF1",
  "RSA-SHA3-256-MGF1",
  "RSA-SHA3-384-MGF1",
  "RSA-SHA3-512-MGF1",
  "ECDSA-SHA256",
  "ECDSA-SHA384",
  "ECDSA-SHA512",
  "DSA-SHA3-224",
  "DSA-SHA3-256",
  "DSA-SHA3-384",
  "DSA-SHA3-512",
  "ECDSA-SHA3-224",
  "ECDSA-SHA3-256",
  "ECDSA-SHA3-384",
  "ECDSA-SHA3-512",
  "RSA-PSS",
  "RSA-OAEP",
];

export const insecureAlgorithms: string[] = [
  "RSA-MD2",
  "RSA-MD5",
  "RSA-SHA1",
  "RSA-SHA224-MGF1",
  "RSA-SHA1-MGF1",
  "ECDSA-SHA1",
  "DSA-SHA224",
  "DSA-SHA256",
  "DSA-SHA384",
  "DSA-SHA512",
];

export const vulnerableAlgorithms: string[] = [
  "RSA-MD2",
  "RSA-MD5",
  "RSA-SHA1",
];
